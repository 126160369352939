/*
======================================
  * CSS TABLE CONTENT *  
======================================
1. HEADER
2. HEADER TOPBAR
3. HEADER LOGO
4. MENU BAR
5. MEGA MENU
6. HEADER TRANSPARENT
7. MENU ICON
8. PAGE BANNER
9. BLOG
10. TESTIMONIALS
11. COUNTERUP
12. FOOTER
13. INNER CONTENT CSS
======================================
  * END TABLE CONTENT *  
======================================
*/

/* Font */
@import url("https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

@font-face{ 
	font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Regular.eot);
	src:url(../fonts/cerebrisans/CerebriSans-Regular.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Regular.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Regular.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Regular.svg) format('svg');
	font-weight:400;
	font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Italic.eot);src:url(../fonts/cerebrisans/CerebriSans-Italic.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Italic.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Italic.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Italic.svg) format('svg');font-weight:400;font-style:italic}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Light.eot);src:url(../fonts/cerebrisans/CerebriSans-Light.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Light.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Light.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Light.svg) format('svg');font-weight:100;font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Medium.eot);src:url(../fonts/cerebrisans/CerebriSans-Medium.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Medium.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Medium.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Medium.svg) format('svg');font-weight:500;font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-SemiBold.eot);src:url(../fonts/cerebrisans/CerebriSans-SemiBold.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-SemiBold.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-SemiBold.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-SemiBold.svg) format('svg');font-weight:600;font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Bold.eot);src:url(../fonts/cerebrisans/CerebriSans-Bold.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Bold.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Bold.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Bold.svg) format('svg');font-weight:700;font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-ExtraBold.eot);src:url(../fonts/cerebrisans/CerebriSans-ExtraBold.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-ExtraBold.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-ExtraBold.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-ExtraBold.svg) format('svg');font-weight:800;font-style:normal}

@font-face{font-family:'CerebriSans';src:url(../fonts/cerebrisans/CerebriSans-Heavy.eot);src:url(../fonts/cerebrisans/CerebriSans-Heavy.eot#iefix) format('embedded-opentype'),url(../fonts/cerebrisans/CerebriSans-Heavy.ttf) format('truetype'),url(../fonts/cerebrisans/CerebriSans-Heavy.woff) format('woff'),url(../fonts/cerebrisans/CerebriSans-Heavy.svg) format('svg');font-weight:900;font-style:normal}

/*=================================
	1. HEADER
=================================*/
.bg-fix {
    background-attachment: fixed;
	background-size: cover;
}
.parallax{
	background-size:cover;
}
.sticky-top {
	top:100px;
}
.header {
    position: relative;
    z-index: 99999;
}
.header ul,
.header ol {
    margin-bottom: 0;
}
.header .container-fluid{
	padding-left:30px;
	padding-right:30px;
}
/*without top bar*/
.secondary-menu {
    float: right;
    padding: 17px 0;
    position: relative;
    z-index: 9;
    height: 80px;
    border-left: 1px solid #cccccc;
    margin-left: 10px;	
}
.secondary-menu .btn-link{
	font-size: 22px;
    padding: 0;
    line-height: 22px;
    padding-top: 5px;
    outline: none !important;
	border: 0;
}
.secondary-menu .btn-link:hover{
	color:var(--primary);
	outline: none !important;	
}
.secondary-menu .secondary-inner {
    display: inline-block;
}
.nav-search-bar {
    background-color:rgba(255,255,255,0.95);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
	height:100%;
    z-index: 999;
    display: none;
    overflow: hidden;
	padding: 0 15px;
	transition: all 0.5s;
}
.nav-search-bar.show{
	display: block;
}
.nav-search-bar.show form {
	transition:all 0.5s ease 0.5s;
	-moz-transition:all 0.5s ease 0.5s;
	-webkit-transition:all 0.5s ease 0.5s;
	opacity:1;
}
.nav-search-bar form {
    width: 100%;
	max-width: 700px;
    margin: auto;
    position: relative;
	top: 50%;
	transition:all 0.5s;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
    -moz-transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    -webkit-transform: translate(0px, -50%);
	opacity:0;
}
.nav-search-bar .form-control {
	padding: 15px 60px 15px 15px;
    width: 100%;
    height: 70px;
    border: none;
    background: none;
    color: #000;
    font-size: 20px;
	border-bottom:2px solid #000;
}
.nav-search-bar .form-control::-moz-placeholder {
    color: #000;
}
.nav-search-bar .form-control:-moz-placeholder {
    color: #000;
}
.nav-search-bar .form-control:-ms-input-placeholder {
    color: #000;
}
.nav-search-bar .form-control::-webkit-input-placeholder {
    color: #000;
}
.nav-search-bar form span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer;
	color: #000;
}
.nav-search-bar > span {
	position: absolute;
	right: 15px;
	top: 15px;
	height: 25px;
	font-size: 20px;
	cursor: pointer;
	color: #000;
}
.header-lang-bx .dropdown-menu{
	top: 10px !important;
	right: 0;
	left: auto !important;
}
.header-lang-bx ul li{
	margin:0 !important;
}
.header-lang-bx .flag:after{
	content: "";
	width: 20px;
	height: 12px;
	display: inline-block;
	background-size: cover;
	background-position: center;
	margin-top: 0px;
	margin-right: 2px;
}
.header-lang-bx .flag.flag-uk:after{
	background-image:url(../images/flag/united-kingdom.svg);
}
.header-lang-bx .flag.flag-us:after{
	background-image:url(../images/flag/united-states-of-america.svg);
}
/* Cart Button */
.cart-btn{
    width: 27px;
    height: 34px;
    border: 2px solid #020d26;
    display: inline-block;
    font-size: 12px;
    position: relative;
    padding: 11px 0 0 0;
    text-align: center;
}
.cart-btn:after{
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    border: 2px solid #020d26;
    -moz-border: 2px solid #020d26;
    -webkit-border: 2px solid #020d26;
    -ms-border: 2px solid #020d26;
    -o-border: 2px solid #020d26;
    border-radius: 0px 0px 12px 12px;
    -moz-border-radius: 0px 0px 12px 12px;
    -webkit-border-radius: 0px 0px 12px 12px;
    -ms-border-radius: 0px 0px 12px 12px;
    -o-border-radius: 0px 0px 12px 12px;
    border-width: 0px 2px 2px 2px;
    top: 4px;
    left: 6px;
}
@media only screen and (max-width: 767px) {

}
@media only screen and (max-width: 480px) {
    .secondary-menu {
        margin: 0 1px 0 0;
        text-align: right;
    }
}


/*=================================
	2. HEADER TOPBAR
=================================*/
.top-bar {
    background-color: #020d26;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    color: #fff;
    padding: 11px 0;
}
.topbar-left {
    float: left;
}
.topbar-right {
    float: right;
}
.topbar-center,
.topbar-left,
.topbar-right {
    padding-left: 15px;
    padding-right: 15px;
}
.topbar-left ul li,
.topbar-right ul li{
	display:inline-block;
	position:relative;
}
.topbar-left ul li a,
.topbar-right ul li a{
	color:#c3cbdd;
	font-size: 15px;
    display: flex;
    align-items: center;	
}
.topbar-left ul,
.topbar-right ul{
	margin:0;
	padding:0;
	list-style:none;
	font-size:14px;
}
.topbar-left ul li{
	padding-right:10px;
	margin-right:10px;
}
.topbar-right ul li{
	padding-left:10px;
	margin-left:10px;
}
.topbar-left ul li i{
	margin-right:5px;
	font-size: 22px;
	color: var(--primary);
}
.topbar-left ul li:after,
.topbar-right ul li:after{
	position: absolute;
    width: 1px;
    height: 20px;
    background-color: #000;
    right: 0;
    content: "";
    top: 2px;
    opacity: 0.1;
}
.topbar-right ul li:after{
	right:auto;
	left:0;
}
.topbar-right ul li:first-child:after,
.topbar-left ul li:last-child:after{
	content:none;
}
.header-lang-bx .btn:focus,
.header-lang-bx .btn:hover,
.header-lang-bx .btn{
	border:0 !important;
}
.header-lang-bx .btn{
	padding:0 10px 0px 0 !important;
	height: 18px;
	color:#000;
	font-size: 14px;
	font-weight: 400;
    text-transform: capitalize;
}
.header-lang-bx .btn:hover{
	color:#000;
}
.header-lang-bx.bootstrap-select.btn-group .dropdown-toggle .caret{
	right:0;
	top:5px;
}
.header-lang-bx .btn .fa-caret-down:before{
	content:"\f107";
}
@media only screen and (max-width: 991px) {
	.topbar-left{
		margin-bottom:5px;
	}
	.topbar-right,
	.topbar-left{
		padding-left: 0;
		padding-right: 0;
		width:100%;
		text-align:center;
	}
	.topbar-right ul li,
	.topbar-left ul li{
		margin:0;
		padding: 0px 5px;
	}
}
@media only screen and (max-width: 767px) {
    .top-bar [class*="col-"] {
        width: 100%;
        text-align: right;
        padding: 10px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .top-bar [class*="col-"]:last-child {
        border-bottom: none;
    }
	.top-bar {
		padding:5px 0;
		display: none;
	}
	.topbar-center,
	.topbar-left,
	.topbar-right {
		display:black;
		width:100%;
		text-align:center;
		padding:3px 15px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.topbar-left ul li{
		padding:0 5px;
	}
}
/*=================================
	3. HEADER LOGO
=================================*/
.menu-logo {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #EFBB20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 160px;
    height: 80px;
    position: relative;
    z-index: 9;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.menu-logo > a{
    display: table-cell;
    vertical-align: middle;
}
.menu-logo img{
	
}
.menu-logo span{
    font-size: 20px;
    color: #EFBB20;
    letter-spacing: 20px;
}
.sticky-header.active .menu-logo{
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.sticky-header.active .logo-change .logo1,
.logo-change .logo2{
	display:none;
}
.sticky-header.active .logo-change .logo2{
	display: block;
}
@media only screen and (max-width: 767px) {
    .menu-logo,
	.sticky-header.active .menu-logo{
        width: 100px;
		max-width: 100px;
		height:50px;
    }
	.menu-logo img{
		max-width: 100px;
	}
	.header .navbar-toggler,
	.header .sticky-header.active .navbar-toggler{
		 margin: 14px 0 14px 15px;
	}
	.header .secondary-menu{
		padding: 7px 0;
		height:50px;
	}
	.btn, 
	.btn-secondry {
		font-size: 14px;
		padding: 9px 15px;
	}
	.secondary-menu .btn-link{
		padding-top: 7px;
	}
}
/*=================================
	4. MENU BAR
=================================*/
.menu-bar {
    background: #FFFFFF;
    width: 100%;
    position: relative;
}
.menu-bar .container{
	position: relative;
}
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin:28px 0 30px 15px;
    padding: 0;
	float:right;
}
.navbar-toggler span {
    background: #000;
}
.menu-links {
    padding: 0;
}
.menu-links .nav {
    float: right;
}
.menu-links .nav i {
    font-size: 9px;
    margin-left: 3px;
    margin-top: -3px;
    vertical-align: middle;
}
.menu-links .nav > li {
    margin: 0px;
    font-weight: 500;
    position: relative;
}
.menu-links .nav > li > a {
    border-radius: 0px;
    color: #020d26;
    font-size: 17px;
    padding: 28px 10px 27px 10px;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    position: relative;
}
.menu-links .nav > li > a:hover {
    background-color: transparent;
    color: #efbb20;
}
.menu-links .nav > li > a:active,
.menu-links .nav > li > a:focus {
    background-color: transparent;
}
.menu-links .nav > li.active > a,
.menu-links .nav > li.current-menu-item > a {
    background-color: transparent;
    color: #EFBB20;
}
.menu-links .nav > li:hover > a {
    color: #EFBB20;
}

.menu-links .nav > li:hover > a:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #fff;
    top: 95%;
    position: absolute;
    left: 30px;
    transform: rotate(45deg);
    z-index: 11;
}
.menu-links .nav > li.no-sub:hover > a:after {
	content:none;
}
.menu-links .nav > li:hover > .sub-menu,
.menu-links .nav > li:hover > .mega-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li > .sub-menu,
.menu-links .nav > li > .mega-menu {
	box-shadow:0 0 40px rgba(0, 0, 0, 0.2);
}
.menu-links .nav > li .sub-menu {
    background-color: #ffffff;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 15px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10;
	border-radius: 0;
	top: 100%;
}
.menu-links .nav > li .sub-menu li {
    position: relative;
}
.menu-links .nav > li .sub-menu li a {
	color: #585e6d;
    display: block;
    font-size: 15px;
    padding: 8px 25px;
    text-transform: capitalize;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    position: relative;
    font-weight: 500;
}
.menu-links .nav > li .mega-menu li a span,
.menu-links .nav > li .sub-menu li a span{
	position:relative;
}
.menu-links .nav > li .mega-menu li a span:after,
.menu-links .nav > li .sub-menu li a span:after{
	content: "";
    position: absolute;
    height: 1px;
    width: 0;
    background: var(--primary);
    bottom: 0;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	-moz-transform-origin:right;
	-webkit-transform-origin:right;
	-ms-transform-origin:right;
	-o-transform-origin:right;
	transform-origin:right;
}
.menu-links .nav > li .mega-menu li:hover > a span:after,
.menu-links .nav > li .sub-menu li:hover > a span:after{
	width:100%;
	-moz-transform-origin:left;
	-webkit-transform-origin:left;
	-ms-transform-origin:left;
	-o-transform-origin:left;
	transform-origin:left;
	left:0;
	right:auto;
}
.menu-links .nav > li .sub-menu li:hover a {
    text-decoration: none;
}
.menu-links .nav > li .sub-menu li:hover > a {
     color: var(--primary);
}
.menu-links .nav > li .sub-menu li:last-child {
    border-bottom: 0px;
}
.menu-links .nav > li .sub-menu li > .sub-menu.left,
.menu-links .nav > li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 220px;
}
.menu-links .nav > li > .sub-menu.left{
	left: auto;
    right: 0;
}
.menu-links .nav > li .sub-menu li .fa {
    color: inherit;
    display: block;
    float: right;
    font-size: 15px;
    position: absolute;
    right: 25px;
    top: 12px;
	opacity: 1;
}
.menu-links .nav > li .sub-menu li .fa.fa-nav {
	color: inherit;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-right: 5px;
    opacity: 1;
    position: unset;
    right: 10px;
    top: 12px;
}
.menu-links .nav > li .sub-menu li > .sub-menu {
    left: 220px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu {
    left: 220px;
    margin: 0px;
    opacity: 1;
    top: -1px;
    visibility: visible;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu:before {
    background-color: transparent;
    bottom: 0px;
    content: '';
    display: block;
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0px;
    width: 6px;
}

/*=================================
	5. MEGA MENU
=================================*/
.menu-links .nav > li.has-mega-menu {
    position: inherit;
}
.menu-links .nav > li .mega-menu {
    background-color: #ffffff;
    display: table;
    left: 0px;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
	z-index: 9;
	    padding: 0;
}
.menu-links .nav > li .mega-menu > li {
    display: table-cell;
    padding: 30px 0 25px;
    position: relative;
    vertical-align: top;
    width: 25%;
}
.menu-links .nav > li .mega-menu > li:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 1px;
    height: 100%;
}
.menu-links .nav > li .mega-menu > li:last-child:after {
    display: none;
}
.menu-links .nav > li .mega-menu > li > a {
	color: #000;
    display: block;
    padding: 0 25px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.menu-links .nav > li .mega-menu > li ul {
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    width: 100%;
}
.menu-links .nav > li .mega-menu > li ul a {
    color: #585e6d;
    display: block;
    font-size: 15px;
    line-height: 34px;
    text-transform: capitalize;
    padding: 0 25px;
}
.menu-links .nav > li .mega-menu > li ul a:hover {
    color: #EFBB20;
}
.menu-links .nav .mega-menu a i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}
.menu-links .nav > li.menu-item-has-children:before {
    content: "\f078";
    display: block;
    font-family: "FontAwesome";
    right: 4px;
    position: absolute;
    top: 50%;
    color: #999;
    margin-top: -8px;
    font-size: 8px;
}
/* Menu */
.menu-links .nav > li.add-mega-menu .mega-menu,
.has-mega-menu.add-mega-menu .mega-menu{
	display: block;
	padding: 20px;
	width: 1170px;
	max-width: 1170px;
	margin: auto;
}
.menu-links .nav > li .add-menu{
    display: flex;
	width:420px;
	left:-60px;
}
.add-menu-left{
    width: 100%;
	padding-left: 25px;
	border-bottom:0 !important;
}
.add-menu-left ul{
	padding:0;
}

.add-menu-right{
    min-width: 240px;
    width: 240px;
    padding: 10px 20px;
}
.menu-links .nav > li.has-mega-menu.demos .mega-menu{
	left:auto;
	right:auto;
	max-width: 600px;
	z-index: 9;
}
.menu-links .nav .add-menu-left ul li{
	list-style:none;
}
.menu-links .nav .add-menu-left ul li a{
	padding: 8px 0px;
}
.menu-links .nav .add-menu-left ul li a:hover{
	background-color:rgba(0,0,0,0);
}
.menu-adv-title{
	font-size: 16px;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    position: relative;
}
.menu-adv-title:after{
	content: "";
	width: 25px;
	height: 2px;
	display: block;
	background: #000;
	position: absolute;
	bottom: 0;
	left: 0;
	
}
.menu-links .menu-logo,
.menu-links .nav-social-link{
	display:none;
}
.header .menu-links .nav > li.dashboard .sub-menu{

}
.header .menu-links .nav > li.dashboard a{
	
}
.header .menu-links .nav > li.dashboard a .icon {
	padding: 0;
    font-size: 18px;
    margin-right: 15px;
    color: var(--primary);
    text-shadow: -4px 10px 15px rgba(46,36,255,0.3);
    margin-left: 0;
    float: none;	
}
/* Header Extra Nav */
.secondary-inner > ul{
	list-style:none;
	padding:0;
	display: flex;
    align-items: center;
	margin:0;
}
.secondary-inner ul li{
	display:inline-block;
	position:relative;
	margin-left: 20px;
}
.secondary-inner i{
	margin:0;
}
.search-btn:after{
	left:0;
	right:auto;
	top: 5px;
}
/* Nav  Badge */
/* Nav  Badge */
.shop-cart.navbar-right{
	margin-right:0;
	float:none;
	display: inline-block;
}
.shop-cart .dropdown-menu.cart-list{
	box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
	top: 50px;
	border:0;
}
.shop-cart .badge{
	font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
}
.shop-cart  li{
	padding: 10px 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	
}
.shop-cart  li:last-child{
	border-bottom:0;
}
.shop-cart .media-left a {
    display: block;
    width: 60px;
}
.shop-cart .site-button-link{
	position:relative;
}
.cart-list.dropdown-menu .title {
	color: #020d26;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 22px;
}
.cart-list.dropdown-menu{
	width: 300px;
    margin-top: 23px;
    right: 0;
    left: auto;
    border: 0;
    box-shadow: 0px 30px 60px 0 rgba(0,0,0,0.1);
}
.cart-list.dropdown-menu li{
	width:100%;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding: 15px 20px;
	margin: 0;
}
.cart-list.dropdown-menu li.cart-item .media .media-left {
    border: 1px solid rgba(0,0,0,0.05);
    padding: 5px;
}
.cart-list .media-object{
	width:50px;
}
.cart-item .media-body .price{
    color: var(--primary);
    font-weight: 700;
}
.cart-item .media-body span{
	font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 10px;
}
.cart-item .media-body{
	position:relative;
	padding-left: 15px;
	padding-right: 25px;
}
.item-close {
    color: #e86161;
	cursor:pointer;
}
.cart-list.dropdown-menu li.cart-item.subtotal .title span {
    float: right;
}
.cart-list.dropdown-menu li.cart-item .item-close {
	color: red;
    font-weight: 300;
    position: absolute;
    line-height: 24px;
    right: 0;
    top: 0;
    margin-right: 0;
    font-size: 35px;
}
@media only screen and (max-width: 1200px) {
	.secondary-inner ul li{
		margin-left: 15px;
	}
	.menu-links .nav > li > a{
		padding: 28px 5px;
		font-size: 16px;	
	}
}
@media only screen and (max-width: 767px) {
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.header .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.cart-list.dropdown-menu{
		margin-top: 6px;
	}
}
@media only screen and (max-width: 991px) {
	[class*="ovbl-"] .counter-style-1 .counter-text, 
	.header-transparent .menu-links .nav > li:hover > a, 
	.header-transparent .menu-links .nav > li.active > a {
		color:#000 !important;
	}
	.add-menu-left ul{
		display: block !important;
	}
	.menu-links .menu-logo,
	.menu-links .nav-social-link{
		display:block;
	}
	.menu-links .nav-social-link{
		margin-top: auto;
		text-align: center;
		width: 100%;
		padding: 10px 0;
		background: #fff;	
	}
	.menu-links .nav-social-link a{
		color:#000;
		padding:5px 10px;
	}
	.menu-links .nav > li.has-mega-menu.demos .mega-menu{
		max-width:100%;
	}
	.menu-links .nav > li .mega-menu{
		border:0;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:10px 0;
	}
	.menu-links .nav > li .sub-menu,
	.menu-links .nav > li .mega-menu {
		border-radius:0;
		border-width: 1px 0 1px 0;
	}
    .menu-links .nav i {
        margin-top: 6px;
		float: right;
    }
    .menu-links {
        clear: both;
        margin: 0 -15px;
        border-bottom: 1px solid #E9E9E9;
    }
    .menu-links .nav {
        float: none;
        background: #fff;
		width:100%;
		display: block;
		margin-bottom: auto;
    }
    .menu-links .nav li {
        float: none;
		display:block;
		width:100%;
    }
    .menu-links .nav > li .sub-menu > li,
    .menu-links .nav > li .mega-menu > li {
        float: none;
        display: block;
        width: auto;
    }
    .menu-links .nav > li > a {
        padding: 10px 15px;
        border-top: 1px dashed #E9E9E9;
		display:block;
    }
    .menu-links .nav > li > a:hover,
    .menu-links .nav > li > a:active,
    .menu-links .nav > li > a:focus {
        background-color: #f0f0f0;
        text-decoration: none;
    }
    .menu-links .nav > li .mega-menu > li:after {
        display: none;
    }
    .menu-links .nav > li ul,
    .menu-links .nav > li .sub-menu,
    .menu-links .nav > li .mega-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: transparent;
    }
    .menu-links .nav > li ul.mega-menu ul {
        display: none;
    }
    .menu-links .nav > li:hover > ul,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu,
    .menu-links .nav > li .sub-menu li > .sub-menu {
        opacity: 1;
        visibility: visible;
        display: block;
        margin: 0;
    }
    .menu-links .nav > li ul.mega-menu li:hover ul {
        display: block;
    }
    .side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
    .nav.navbar-nav li a i.fa-chevron-down:before {
		content:"\f078";
	}
	.side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before,
	.nav.navbar-nav li.open a i.fa-chevron-down:before {
		content:"\f054";
	}
	.menu-links .nav > li .sub-menu li i.fa-angle-right:before{
		content: "\f078";
		font-size: 10px;
		position: absolute;
		z-index: 2;
		color: #000;
		right: 20px;
		top: -5px;
	}
	.menu-links .nav > li .sub-menu li.open i.fa-angle-right:before{
		content: "\f054";
	}
    .menu-links .nav > li .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu {
        display: none;
        opacity: 1;
        margin-top: 0;
    }
	.menu-links .nav li .sub-menu .sub-menu{
		display: none;
        opacity: 1;
        margin-top: 0;
	}
	.menu-links .nav > li.open > .sub-menu .sub-menu{
		display: none;
	}
	.menu-links .nav > li.open > .sub-menu li.open .sub-menu,
    .menu-links .nav > li.open > .mega-menu,
    .menu-links .nav > li.open > .sub-menu,
	.menu-links .nav > li ul.mega-menu ul{
        display: block;
        opacity: 1;
        margin-top: 0;
		box-shadow: none;
    }
	.menu-links .nav > li:hover > a:after{
		content:none;
	}
	.menu-links .nav > li .sub-menu li .fa{
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		margin: 0;
		right: -1px;
		color:#000;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:0;
	}
	.menu-links .nav > li .mega-menu > li > a{
		display:none;
	}
	.menu-links .nav .mega-menu a i{
		display:inline-block;
		float:none;
		margin-top: 0;
	}
    .menu-links .nav .open > a,
    .menu-links .nav .open > a:focus,
    .menu-links .nav .open > a:hover {
        background-color: inherit;
        border-color: #e9e9e9;
    }
	.menu-links .nav > li .sub-menu li a{
		padding: 8px 15px;
	}
	.add-menu-left{
		padding-left: 0;
	}
	.add-menu-right{
		padding: 10px 15px;
	}
	.menu-links .nav > li .mega-menu > li ul a{
		padding: 2px 15px;
	}
	.style-3 .navbar-toggler span{
		background:#fff;
	}
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.rs-nav .menu-links.nav-dark{
		background-color:#202020;
	}
	.rs-nav .menu-links{
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh !important;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-o-transition:all 0.5s;
		top:0;
		background-color:#fff;
		margin:0;
		z-index:99;
		overflow-y: scroll;
		flex-direction: column;
	}
	.rs-nav .menu-links li.open a{
		position:relative;
	}
	.navbar-nav{
		height:auto;
	}
	.rs-nav .menu-links.show {
		left: -1px;
		transition:all 0.8s;
		-webkit-transition:all 0.8s;
		-moz-transition:all 0.8s;
		-o-transition:all 0.8s;
		margin:0;
		width: 100%;
		width: 300px;
		padding: 15px 15px 5px 15px;
	}
	.rs-nav .sticky-header.active .menu-links .nav{
		height:auto;
	}
	.rs-nav .navbar-toggler.open:after{
		background-color: rgba(0, 0, 0, 0.6);
		content: "";
		height: 100%;
		left: 0;
		position: fixed;
		right: 0px;
		top: -20px;
		transform: scale(100);
		-o-transform: scale(100);
		-moz-transform: scale(100);
		-webkit-transform: scale(100);
		width: 100%;
		z-index: -1;
		transition: all 0.5s;
		transform-origin: top right;
		margin: 0 0px 0px 10px;
		box-shadow: 0 0 0 500px rgba(0,0,0,0.6);
	}
	.rs-nav .menu-links .menu-logo {
		display: block;
		float: none;
		height: auto;
		max-width: 100%;
		padding: 20px 15px;
		width: 100%;
		text-align:center;
	}
	.rs-nav .menu-links .menu-logo img{
		max-width: unset;
		width: 130px;
		vertical-align: middle;
	}
	.rs-nav .menu-links .menu-logo a{
		display:inline-block;
	}
	.rs-nav .navbar-toggler.open span{
		background:#fff;
	}
	.menu-links .nav > li > .mega-menu,
	.menu-links .nav > li > .sub-menu{
		border-top: 1px dashed rgba(0,0,0,0.1);
		border-top: 1px dashed rgba(0,0,0,0.1);
		
	}
	.menu-links .nav > li .mega-menu{
		padding: 15px 0;
	}
}
.sticky-no .menu-bar {
    position: static !important;
}
.sticky-header.active .menu-bar {
    position: fixed;
    top: 0;
    left: 0;
	box-shadow:0 0 50px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 991px) {
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .sticky-header.active .menu-links .nav {
        height: 225px;
    }
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}

/*=================================
	6. HEADER TRANSPARENT
=================================*/
.header-transparent{
	position:absolute;
	width:100%;
}
.header-transparent .menu-bar{
	background-color:rgba(0,0,0,0);
	border-bottom: 1px solid rgba(255,255,255,0.1);
}
.header-transparent .menu-links .nav > li > a{
	color:#fff;
}
.header-transparent .menu-links .nav > li > a,
.header-transparent .secondary-menu .btn-link,
.header-transparent .navbar-toggler{
    color: #fff;
}
.header-transparent .secondary-menu .btn-link:hover{
	color:var(--primary);
}
.header-transparent .navbar-toggler span{
    background-color: #fff;
}
.header-transparent .sticky-header.active .menu-bar{
	position:fixed;
	background-color:rgba(0,0,0,0.9);
}
.header-transparent .secondary-menu{
	border-left: 1px solid rgba(255, 255, 255, 0.08);
}
.header-transparent .top-bar{
	background-color: rgba(0,0,0,0);
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}
.header-transparent .search-btn:after,
.header-transparent .topbar-left ul li:after,
.header-transparent .topbar-right ul li:after{
	background-color:#fff;
}
.header-transparent .cart-btn:after,
.header-transparent .cart-btn{
	border-color:#fff;
}
.fullwidth .container-fluid{
	padding-left: 30px;
    padding-right: 30px;
}
.onepage .navbar{
	margin-bottom:0;
	border:0;
}
.header-transparent .header-lang-bx .btn{
	background-color: transparent !important;
	color:#fff;	
} 
.header-transparent .topbar-right .header-lang-bx ul li a{
	color:#000;	
}
.header-transparent .topbar-left ul li a, 
.header-transparent .topbar-right ul li a{
	color:#fff;	
}
.style-2 .sticky-header{
	position:absolute;
	width:100%;
}
.style-2 .menu-bar {
    background: transparent;
}
.style-2 .secondary-menu{
	background: #fff;
    padding: 17px 20px 17px 0;
    position: relative;
    border: 0;
}
.style-2 .secondary-menu:after{
    content: "";
    position: absolute;
    height: 100%;
    width: 30px;
    background: #fff;
    transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    left: -10px;
    z-index: -1;
    top: 0;
	border-left: 5px solid #000;
}
.style-2 .menu-bar .menu-links .nav{
	padding-right:20px;
}

@media only screen and (min-width: 991px) {
	.style-2 .menu-bar .menu-links .nav > li > a{
		color:#fff;
	}
	.style-2 .menu-bar .menu-logo{
		padding: 0 20px;
		border-right:1px solid rgba(255,255,255,0.2);
	}
}
.style-2 .menu-bar .container{
	position:relative;
}
.style-2 .menu-bar .container:after{
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    background: #5096C9;
    left: 15px;
    z-index: -1;
    top: 0;
}
/* Style 3 */
.style-3 .menu-bar{
	background-color:var(--primary);
}
.aside-btn{
	width: 30px;
    height: 22px;
    position: relative;
    display: block;
}
.aside-btn span{
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background:#fff;
  opacity: 1;
  left: 0;
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.aside-btn span:nth-child(1) {
	top: 0px;
}
.aside-btn span:nth-child(2) {
	top: 50%;
	transform:translateY(-50%)
}
.aside-btn span:nth-child(3) {
	bottom: 0;
}
.secondary-inner ul li{
	
}
.aside-box{
	
}
.style-3 .secondary-menu{
	padding: 0;
    margin-left: 0;
    border: 0;
}
@media only screen and (min-width: 991px) {
	.style-3 .menu-links .nav > li.active > a,
	.style-3 .menu-links .nav > li:hover > a,
	.style-3 .menu-links .nav > li > a{
		color:#fff;
	}
}
.search-box form{
    height: 80px;
    position: relative;
    background: rgba(0,0,0,0.15);
}
.search-box form button{
	border: 0;
    background: transparent;
    color: #fff;
    position: absolute;
    top: 28px;
    font-size: 20px;
    padding: 0 0 0 20px;
    left: 0;	
}
.search-box form .form-control{
    height: 80px;
    border: 0;
    background: transparent;
    color: #fff;
    padding: 4px 15px 0px 50px;
    font-size: 18px;
    width: 190px;
    font-weight: 400;
}
.search-box form .form-control::placeholder {
    color: #fff;
}
.search-box form .form-control::-moz-placeholder {
    color: #fff;
}
.search-box form .form-control::-ms-input-placeholder {
    color: #fff;
}
.search-box form.form-control::-webkit-input-placeholder {
    color: #fff;
}

.style-3 .cart-btn{
	color: #fff;
	border: 2px solid #fff;		
}
.style-3 .cart-btn:after{
    border: 2px solid #fff;
    -moz-border: 2px solid #fff;
    -webkit-border: 2px solid #fff;
    -ms-border: 2px solid #fff;
    -o-border: 2px solid #fff;
    border-width: 0px 2px 2px 2px;	
}
.style-3.box .menu-bar {
    background-color: transparent;
}
.style-3.box .menu-bar .container{
    background-color: var(--primary);
    border-radius: 4px;	
}
.style-3.box .secondary-inner > ul > li {
    margin-left: 15px;
	margin-right: 10px;
}
.style-3.box{
	 margin-bottom: 40px;
}
.style-3.box .sticky-header{
	position:absolute;
	width:100%;
	height:40px;
	z-index: 99999;	
}

/* Extra Info */
.header-extra-info{
    padding: 20px 0;
	width: 100%;	
}
.header-contant-info ul{
	margin:0;
	padding:0;
	list-style:none;
    display: flex;
    align-items: center;	
}
.header-contant-info ul li{
    display: inline-block;
    padding-left: 60px;
    position: relative;
    padding: 5px 20px 0 60px;
    margin-right: 20px;
}
.header-contant-info ul li:first-child{
	border-right: 1px solid rgba(0,0,0,0.2);
}
.header-contant-info ul li:last-child{
	padding:0;
	margin-right:0;
}
.header-contant-info ul li .icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    position: absolute;
    left: 0;
    top: 0;	
}
.header-contant-info ul li .title{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    line-height: 20px;
}
.header-contant-info ul li p{
    color: #6d7380;
    margin: 0;
    font-size: 15px;
}
.extra-logo{
	max-width:210px;
}
.extra-info-inner{
	display: flex;
    justify-content: space-between;
    align-items: center;	
}


@media only screen and (max-width: 991px) {
	.header-transparent .menu-links .nav > li > a{
		color:#000;
	}
	.header-transparent .menu-links.nav-dark .nav > li > a{
		color:#fff;
	}
	.style-3.box .navbar-toggler{
		margin: 28px 0px 30px 15px;
	}
	.style-3.box .sticky-header{
		position: unset;
		width: 100%;
		height: auto;
		background-color: var(--primary);
	}
	.style-3.box {
		margin-bottom: 0;
	}
	.style-2 .sticky-header {
		position: relative;
	}
	.style-2 .menu-bar {
		background:  var(--primary);
	}
	 .style-2 .menu-bar:before{
		height:100%;
		width:25%;
		position:absolute;
		top:0;
		right:0;
		content:"";
		background:#fff;
	}
	.style-2 .secondary-menu {
		padding: 17px 0px 17px 0;
	}
}
@media only screen and (max-width: 767px) {
	.style-2 .secondary-menu{
		padding: 7px 0px 7px 0;
	}
	.fullwidth .container-fluid{
		padding-left: 15px;
		padding-right: 15px;
	}
	.search-box form .form-control {
		height: 50px;
	    font-size: 14px;	
		width: 120px;
	    padding: 0px 15px 0px 40px;		
	}
	.search-box form{
		height: 50px;
		font-size: 14px;	
	}
	.search-box form button	{
		top: 14px;
		font-size: 18px;
		padding: 0 0 0 15px;	
	}
	.style-3.box .secondary-inner ul li {
		margin-left: 10px;
		margin-right: 0;
	}
	.style-3.box .navbar-toggler {
		margin: 14px 0px 14px 15px;
	}

}

/*=================================
	7. MENU ICON
=================================*/
.menuicon{
    width: 22px;
    height: 22px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 99;
}
.menuicon span{
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.menuicon span:nth-child(1) {
  top: 0px;
}
.menuicon span:nth-child(2) {
  top: 50%;
  transform:translateY(-50%)
}
.menuicon span:nth-child(3) {
  bottom: 0;
}
.menuicon.open span:nth-child(1) {
  top: 50%;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.menuicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.menuicon.open span:nth-child(3) {
  top: 50%;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.menuicon i{
	position:relative;
	width: 14px;
	display: inline-block;
	height: 12px;
	margin-left:5px;
}
.menuicon.open i{
	height:12px;
}
.menuicon.open i span:nth-child(1),
.menuicon.open i span:nth-child(3) {
	top:5px;
}
.header-transparent .extmenu a{
	color:#fff;
	text-transform: uppercase;
	font-weight: 600;
}
.extmenu a span{
	background:#fff;
}
/*========================
	8. PAGE BANNER
=========================*/
.page-banner {
    height: 320px;
    background-size: cover;
    background-position: center center;
    display: table;
    width: 100%;
	text-align:left;
	background-color: #f7f7f7;
}
.page-banner.bg-gray {
	background-color:#eef2f8;
}
.bg-yellow {
	background-color:#FDCC03;
}
.page-banner-sm {
    height: 220px;  
}
.page-banner-lg {
    height: 500px;  
}
.page-banner .container {
    display: table;
    height: 100%;
}
.page-banner-entry {
    display: table-cell;
    vertical-align: middle;
	text-align: center;
}
.page-banner-entry.align-m {
    vertical-align: middle;
}
.page-banner h1 {
	font-size: 45px;
    margin-bottom: 10px;
    line-height: 55px;
    font-weight: 500;
    color: #020d26;
}
.page-banner h1.text-white{
	color: #fff;
}
.breadcrumb-row ul li a{
	color:var(--sc-primary);
}
.banner-sm-title {
    color: var(--sc-primary);
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}
.breadcrumb-row.text-black ul li{
    color: #686767;
    font-weight: 500;
    font-size: 16px;
}
.breadcrumb-row.text-black ul li a{
	color:var(--primary);
}
@media only screen and (min-width: 991px) {
	.header.style-2 + .page-content .page-banner{
		padding-top: 80px;
	}
}
@media only screen and (max-width: 767px) {
	.page-banner-entry{
		vertical-align: middle;
	}
	.page-banner{
		padding-bottom: 0;
		height: 200px;
	}
	.page-banner h1{
		line-height: 24px;
		font-size: 20px;
		text-align:center;
	}
}
/*========================
	9. BLOG
=========================*/
.blog-post{
    position: relative;
    margin-bottom: 50px;
}
.ttr-post-title {
    margin-bottom: 5px;
}
.ttr-post-title .post-title {
    margin-bottom: 5px;
}
.ttr-post-meta {
    margin-bottom: 15px;
}
.ttr-post-meta ul {
    list-style: none;
	text-transform:capitalize;
	margin: 0;
    padding: 0;
}
.ttr-post-meta ul li {
    padding: 0;
    display: inline-block;
    color: #25314a;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}
.ttr-post-meta ul li strong{
	font-weight: 500;
}
.ttr-post-meta li:after {
    content: "|";
    display: inline-block;
    font-weight: normal;
    margin-left: 5px;
    opacity: 0.5;
}
.ttr-post-meta li:last-child:after {
    display: none;
}
.ttr-post-meta a {
    color:var(--primary);
}
.ttr-post-meta i {
    margin: 0 5px;
	font-size: 15px;
}
.ttr-post-text {
    margin-bottom: 20px;
}
.ttr-post-text p {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 1.5;
}
.ttr-post-text p:last-child {
    margin: 0;
}
.blog-group .blog-post{
	margin-bottom:30px;
}
.blog-group .blog-post{
	display: flex;
}
.blog-group .blog-post .ttr-post-info{
	padding: 30px;
}
.blog-group .blog-md .ttr-post-media {
    width: 350px;
    max-width: 350px;
    min-width: 350px;
}
.blog-group .blog-md .ttr-post-media img {
    height: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 1200px) {
	.ttr-post-meta .d-flex{
		display:block !important;
	}
}
/*blog post half iamge*/

.blog-md .ttr-post-media {
    width: 350px;
	border-radius: 4px;
}
.blog-md.sm-large,
.blog-md.md-large{
	display: flex;
    flex-wrap: wrap;
}
.blog-md.md-large{
	margin-bottom:60px;
}
.blog-md.md-large .ttr-post-media {
	width:55%;
	flex: 0 0 55%;
}
.blog-md.md-large .ttr-post-info{
	width:45%;
	flex: 0 0 45%;
	display: flex;
    flex-direction: column;
	padding: 30px 0 30px 30px;
}
.blog-md.md-large .post-title{
	font-size:40px;
	line-height:50px;
}
.blog-md.sm-large .media-post li a,
.blog-md.md-large .media-post li a {
    color: #a3b1c7;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
}
.blog-md.sm-large .media-post li a{
	font-size: 14px;
}
.blog-md.md-large p{
    font-size: 18px;
    color: #585a86;
	line-height: 32px;
}
.blog-md.sm-large .media-post,
.blog-md.md-large .media-post{
	border:0;
	padding: 0;
    margin-bottom: 10px;
}
.blog-md.md-large .ttr-post-info .post-title{
	margin-bottom: 10px;
}
.blog-md.sm-large .ttr-post-info  .post-extra,
.blog-md.md-large .ttr-post-info  .post-extra{
	border:0;
	margin-top:auto;
}

.blog-md.sm-large{
	margin-bottom:0;
}
.blog-md.sm-large .ttr-post-media {
	width:45%;
	flex: 0 0 45%;
	display: flex;
    align-items: stretch;
}
.blog-md.sm-large .ttr-post-info{
	width:55%;
	flex: 0 0 55%;
	display: flex;
    flex-direction: column;
	padding: 0 0 0 30px;
}
.blog-md.sm-large .post-title{
	font-size:22px;
	line-height:32px;
}

.blog-md .ttr-post-info {
    border: none;
	padding-left:30px;
}
.blog-md .ttr-post-tags {
    border: none;
    display: inline-block;
    padding: 0;
}
.blog-md .ttr-post-info .post-title{
	margin-top:0;
}
.blog-md, 
.blog-md .ttr-post-info {
    overflow: hidden;
}
.blog-md .ttr-post-info, 
.blog-md .ttr-post-media {
    display: table-cell;
    vertical-align: middle;
}
.blog-md .ttr-post-media {
	vertical-align: top;
}
.blog-md .ttr-post-info > div:last-child{
	margin-bottom:0;
}
/* blog-area */
.blog-area .heading-bx {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.side-post{
	border-left: 1px solid rgba(0,0,0,0.1);
}
.side-post .blog-post.blog-md{
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-bottom:30px;
	padding-bottom:30px;
	padding-left:30px;
}
.side-post .blog-post.blog-md:last-child{
	border-bottom:0;
	margin-bottom:0;
	padding-bottom:0;
}
.side-post .blog-md .ttr-post-info h4 {
    font-size: 24px;
}
.side-post .blog-md .ttr-post-info{
    padding-left: 20px;
}
.side-post .blog-md .ttr-post-media {
	width: 200px;
}

/* Blog Share */
.blog-share{
	position:relative
}
.share-btn{
	position:absolute;
	right:0;
	bottom:0;
}
.share-btn ul{
	margin:0;
	padding:0;
	list-style:none;
}
.share-btn ul li{
	display:inline-block;
	margin-left: -40px;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
}
.share-btn ul li a.btn{
	border-radius: 3px;
	width: 40px;
	line-height: 44px;
	height:44px;
	display: block;
	color:#000;
	background: #E6E6E6;
}
.share-btn ul li a.btn:hover{
	background:#D6D6D6;
}
.share-btn ul:hover li.share-button a.btn{
	background:#A0A0A0;
}	
.share-btn ul:hover li{
	margin-left: 4px;
}
.share-details-btn ul{
	margin:0;
	padding:0;
	list-style:none;
}
.share-details-btn ul li{
	display:inline-block;
	margin-right: 5px;
	margin-bottom: 6px;
}
/* recent news style 2 */
.recent-news{
	margin-bottom:50px;
	position: relative;
}
.blog-post .media-post li a,
.blog-single .media-post li a,
.recent-news .media-post li a{
	font-weight: 400;
    color: #696969;
    font-size: 15px;
    letter-spacing: unset;
    line-height: 22px;	
}
.blog-post .media-post,
.blog-single .media-post,
.recent-news .media-post{
	display: flex;
    align-items: center;
	border-bottom:0;
	padding:0;
	margin-bottom: 10px;
    list-style: none;	
}
.blog-single .post-title,
.blog-post .post-title,
.recent-news .post-title{
	font-weight:700;
}
.blog-post .post-title,
.recent-news .post-title{
	margin-bottom: 10px;
}
.recent-news .media-post li.date{
	padding:0;
	line-height: 14px;
}
.recent-news .media-post li{
	padding: 0 20px 0 0px;
	position:relative;
}
.recent-news .media-post li.admin a img{
	width: 36px;
    height: 36px;
	margin-right: 15px;
    border-radius: 36px;
}
.recent-news .media-post li.comment a{
	display: flex;
}
.blog-post .media-post li a i,
.recent-news .media-post li a i{
	margin-right:5px;
}
.blog-post .btn-link,
.recent-news .btn-link{
	color:var(--primary);
	font-size:15px;
	font-weight:600;
	border-bottom:1px solid;
	border-radius:0;
	text-decoration: none !important;	
}
.blog-post .ttr-post-info p,
.recent-news .info-bx p{
    font-size: 16px;
    line-height: 1.5;
    color: #8f8f8f;
    font-weight: 400;
    margin-bottom: 15px;
}
.blog-post .ttr-post-info p{
	margin-bottom:10px;
}
.blog-post .media-post{
	margin-bottom: 10px;
}
.recent-news .info-bx{
	position:unset;
	padding-top: 25px;
}
.recent-news .post-extra{
    padding: 0;
    border: 0;
	position: relative;
}
.recent-news .post-extra .btn{
	padding: 13px 30px;
}

.aside-bx{
    border-left: 1px solid rgba(0,0,0,0.1);
    padding-left: 30px;
}










@media only screen and (max-width: 767px) {
    .blog-md.blog-post .ttr-pfost-media,
    .blog-md.blog-post .ttr-post-info {
        float: none;
        margin: 0 0 20px;
        width: 100%;
		display:block;
		padding-left:0;
    }
	.blog-md.blog-post .ttr-post-info {
		margin-top:20px;
	}
	.blog-md .ttr-post-media {
		width:100%;
	}
}
/*Blog single*/
.blog-single{
    margin-bottom: 30px;
}
.blog-single .media-post{
	padding:0;
	
}
.blog-single .action-box{
	margin-bottom:30px;
}
.blog-single .ttr-post-text ul{
	padding: 0 0 0 30px;
}
.blog-single .ttr-post-text ul li{
    padding: 5px 0;
    font-size: 18px;
}
.blog-single .ttr-post-meta .post-tags a{
	color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
}
.blog-single .ttr-post-meta {
    margin-bottom: 0;
}
.blog-single .ttr-post-text {
    margin-top: 10px;
}
.blog-single .ttr-post-tags {
    margin-top: 20px;
}
.blog-single .ttr-post-media{
	border-radius:4px;
}
/*= comment list = */
.comments-area {
    padding: 0;
}
.comments-area .comments-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}
ol.comment-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
ol.comment-list li.comment {
    position: relative;
    padding: 0;
}
ol.comment-list li.comment .comment-body {
	margin-bottom: 40px;
    margin-left: 80px;
    position: relative;
    background: #FDCC03;
    padding: 30px 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
ol.comment-list li.comment .comment-body:after{
    content: "";
    background: #FDCC03;
    border: 1px solid rgba(0,0,0,0.1);
    width: 12px;
    height: 12px;
    position: absolute;
    left: -6px;
    transform: rotate(45deg);
    border-width: 0 0px 1px 1px;
    top: 30px;
}
ol.comment-list li.comment .comment-author {
    display: block;
    margin-bottom: 0;
}
ol.comment-list li.comment .comment-author .avatar {
    position: absolute;
    top: 0;
    left: -80px;
    width: 70px;
    height: 70px;
    border-radius: 64px;
    -webkit-border-radius: 64px;
    background: #fff;
    padding: 4px;
    box-shadow: 0 0 30px 0 rgba(0,10,105,0.03);
}
ol.comment-list li.comment .comment-author .fn {
    display: inline-block;
    color: #272d3a;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 5px;
    line-height: 28px;
}
ol.comment-list li.comment .comment-author .says {
    display: none;
    color: #999999;
    font-weight: 600;
}
ol.comment-list li.comment .comment-meta {
    color: #8d8d8d;
    text-transform: uppercase;
    margin-bottom: 5px;
}
ol.comment-list li.comment .comment-meta a {
    color: #8d8d8d;
}
ol.comment-list li.comment .comment-meta a {
	color: var(--primary);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: capitalize;
}
ol.comment-list li.comment p {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
ol.comment-list li.comment .reply a {
background: #020d26;
    padding: 6px 15px;
    display: inline-block;
    border-radius: 0;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
}
ol.comment-list li.comment .reply a:hover {
	background-color: var(--primary);
	color:#fff;
}
ol.comment-list li .children {
    list-style: none;
    margin-left: 80px;
	padding: 0;
}
ol.comment-list li .children li {
    padding: 0;
}
@media only screen and (max-width: 767px) {
    .comments-area .padding-30 {
        padding: 15px;
    }
    ol.comment-list li.comment .comment-body {
        margin-bottom: 30px;
        margin-left: 70px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -75px;
        height: 60px;
        width: 60px;
    }
    ol.comment-list li .children {
        margin-left: 20px;
    }
    ol.comment-list li.comment .reply a {
        position: static;
    }
}
@media only screen and (max-width: 480px) {
    ol.comment-list li.comment .comment-body {
        margin-left: 52px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -55px;
        top: 12px;
        width: 40px;
        height: 40px;
    }
}
/*= comment form = */
.comment-respond {
    padding: 30px 30px;
    background: #f6f7f8;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
.comment-respond .comment-reply-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}
.comments-area .comment-form {
    margin: 0 -15px;
}
.comments-area .comment-form .comment-notes {
    display: none;
}
.comments-area .comment-form p {
    width: 33.333%;
    float: left;
    padding: 0 15px;
    margin-bottom: 30px;
    position: relative;
}
.comments-area .comment-form p.form-allowed-tags {
    width: 100%;
}
ol.comment-list li.comment .comment-respond .comment-form p {
    padding: 0 15px !important;
}
.comments-area .comment-form p label {
    display: none;
    line-height: 18px;
    margin-bottom: 10px;
}
.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
	width: 100%;
    height: 45px;
    padding: 14px 22px 14px 22px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    text-transform: capitalize;
    color: #000000;
    background: #fff;
    font-size: 14px;
}
.comments-area .comment-form p input[type="text"]:hover,
.comments-area .comment-form p textarea:hover,
.comments-area .comment-form p input[type="text"]:focus,
.comments-area .comment-form p textarea:focus{
	border-color:var(--primary);
	box-shadow:none !important;
	    outline: none;
}
.comments-area .comment-form p.comment-form-comment {
    width: 100%;
    display: block;
    clear: both;
}
.comments-area .comment-form p textarea {
    height: 120px;
}
.comments-area .comment-form p.form-submit {
    clear: both;
    float: none;
    width: 100%;
    margin: 0;
}
.comments-area .comment-form p input[type="submit"] {
    background-color: #EFBB20;
    border: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 30px;
}
.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
    background-color: var(--sc-primary);
    border-color: #6ab33e;
    color: #fff;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-form p {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
    .comment-respond {
        padding: 20px;
    }
}
/* Blog User */
.author-profile-pic {
    width: 125px;
    height: 125px;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: -2px 5px 10px 0 rgba(0,16,147,0.1);
    z-index: 2;
    position: relative;
	margin: auto;
}
.author-profile-content ul li a{
	padding: 0 2px;
    background: var(--primary);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    line-height: 40px;
	text-align:center;
}
.author-profile-content ul li a:hover{
	background: var(--sc-primary);
	color:#fff;
}
.author-profile-content ul li a i{
	margin:0;
}
.author-box{
    background-color: #f6f7f8;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
.author-box.blog-user .author-profile-info{
	display: flex;
}
.blog-user .author-profile-pic img{
	width:100%;
}
.blog-user .author-profile-pic {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow:none;
    z-index: 2;
    position: relative;
	margin: 0 auto;
	border:0;
}
.blog-user .author-profile-content {
    padding-left: 20px;
	text-align:left;
	padding-top: 0;
	width: 100%;
}
.blog-user .author-profile-content h6{
	font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
}
.blog-user .author-profile-content p {
	margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
}
/* Gutenberg Css */
.min-container,
.blog-post.blog-single{
	max-width: 750px;
    margin-left: auto;
    margin-right: auto;
	padding-left: 15px;
    padding-right: 15px;
}
.max-container{
	max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
	padding-left: 55px;
    padding-right: 55px;
}
.blog-post.blog-single .ttr-post-info{
	padding:0;
}
.blog-post.blog-single .ttr-post-text.text > ol,
.blog-post.blog-single .ttr-post-text.text > ul {
    padding-left: 30px;
}
.blog-post.blog-single .ttr-post-text.text > ol li,
.blog-post.blog-single .ttr-post-text.text > ul li {
	padding: 5px 5px;
	color: #2b2d2e;
}
.blog-post.blog-single .ttr-post-text.text > .wp-block-gallery {
	padding: 0;
	list-style: none;
}
.blog-post.blog-single .ttr-post-text.text > .wp-block-gallery li{
	padding: 0;
	list-style: none;
	margin:10px;
}
.wp-block-gallery .blocks-gallery-image, 
.wp-block-gallery .blocks-gallery-item{
	margin: 0 8px 8px 0;
}
.wp-block-gallery.columns-4 .blocks-gallery-image, 
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image, 
.wp-block-gallery.columns-3 .blocks-gallery-item{
	margin-right: 8px;
}
.content-spacious-full .wp-block-gallery {
    margin-left: calc(-200px - (10px/2));
    margin-right: calc(-200px - (10px/2));
    width: calc(100% + 400px + 10px);
    max-width: initial;
}
.alignwide{
	margin-left: calc(-100px - (10px/2));
    margin-right: calc(-100px - (10px/2));
    width: calc(100% + 200px + 10px);
    max-width: initial;
}
.alignfullwide{
	margin-left: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
	width: 100vw;
	max-width: 100vw;
	box-sizing: border-box;
}
.ttr-post-text h1,
.thm-unit-test .ttr-post-text h1 {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 10px;
	font-weight: 700;
}
.ttr-post-text h2,
.thm-unit-test .ttr-post-text h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 5px;
	font-weight: 700;
}
.ttr-post-text h3,
.thm-unit-test .ttr-post-text h3 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 8px;
	font-weight: 700;
}
.ttr-post-text h4,
.thm-unit-test .ttr-post-text h4 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
	font-weight: 600;
}
.ttr-post-text h5,
.thm-unit-test .ttr-post-text h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
	font-weight: 600;
}
.ttr-post-text h6,
.thm-unit-test .ttr-post-text h6 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
	font-weight: 600;
}
pre {
	margin: 0 0 25px;
    padding: 20px;
    color: #ddd;
    background-color: #222;
    white-space: pre;
    text-shadow: 0 1px 0 #000;
    border-radius: 5px;
    border-bottom: 1px solid #555;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4) inset, 0 0 20px rgba(0, 0, 0, 0.2) inset;
}
.blog-post.blog-single .ttr-post-text ul li,
.blog-post.blog-single .ttr-post-text ol li,
.blog-post.blog-single .ttr-post-text p {
	font-size:15px;
	line-height:28px;
}
.blog-post.blog-single .ttr-post-text p {
	margin-bottom:28px;
}
.blog-post.blog-single .ttr-post-text blockquote p{
	font-size: 18px;
	line-height: 30px;
	color: #2b2d2e;
	margin-bottom: 10px;
	font-weight: 600;
	text-align: left;
}
.wp-block-table{
	
}
.blog-post.blog-single .ttr-post-text .wp-block-gallery {
    margin-left: calc(-190px - (10px/2));
    margin-right: calc(-190px - (10px/2));
    width: calc(100% + 380px + 10px);
    max-width: initial;
}
.blog-post.blog-single .ttr-post-text > div + p:first-letter,
.blog-post.blog-single .ttr-post-text > p:first-child:first-letter{
    color: #6d9ec4;
    font-size: 100px;
    float: left;
    margin-right: 20px;
    margin-bottom: 14px;
	margin-top: 15px;
}
.paginate-links {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.paginate-links a, 
.paginate-links > span {
    margin: 0 0 0 10px;
    position: relative;
    border: 0px solid #6cc000;
    color: #000;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    background: #E0E0E0;
    border-radius: 3px;
}
.wp-block-button__link {
    border-radius: 4px;
    font-size: 16px;
    padding: 10px 30px;
    font-weight: 700;
}
.blog-post.blog-single .ttr-post-text .has-large-font-size {
    font-size: 36px;
    line-height: 50px;
	color: #000;
}
/* Gutenberg Gallery */
.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 !important;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 6px 6px 0;
    position: relative
}
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
    bottom: 0;
    color: #fff;
    font-size: 13px;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 5px;
    position: absolute;
    text-align: center;
    width: 100%
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 8px)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%
}

@media only screen and (max-width: 1024px) {
	.alignwide{
		margin-left: 0;
		margin-right:0;
		width: 100% !important;
		max-width: 100% !important;
	}
	.alignwide .alignleft img{
		width: 100%;
	}
	
	.alignwide .alignleft{
		margin: 5px 0px 25px 0;
		float: none;
	}
}
@media (min-width:600px) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(33.33333% - 10.66667px)
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(25% - 12px)
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(20% - 12.8px)
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(16.66667% - 13.33333px)
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(14.28571% - 13.71429px)
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(12.5% - 14px)
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}
.wp-block-image.alignfullwide img{
	border-radius:0;
}
.wp-block-image img{
	border-radius:10px;
}
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex
}
.aligncenter{
	text-align:center;
}
.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}
/*========================
	10. TESTIMONIALS
=========================*/
.testimonial-pic {
    background: #FFF;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #FFF;
}
.testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
    width: 100%;
    height: 100;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.testimonial-text {
    padding: 15px;
    position: relative;
	font-size:15px;
	font-weight: 400;
}
.testimonial-detail {
    padding: 5px;
}
.testimonial-name{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
.testimonial-position {
	font-size: 12px;
    font-style: inherit;
    text-transform: uppercase;
}
.testimonial-name,
.testimonial-position {
    display: block;
}
.testimonial-text p:last-child {
    margin: 0;
}
/*========================
	11. COUNTERUP
=========================*/
.counter {
    position: relative;
}
.counter-style-1 .counter{
	font-size:70px;
	font-weight:700;
	line-height: 70px;
	display: inline-block;
}
.counter-style-1 .counter-text{
	font-size:16px;	
	font-weight: 500;
}
.counter-style-1.text-black .counter-text{
	color:#000;
}
.counter-style-1 .icon{
	font-size:45px;
	margin-right:10px;
}
.counter-style-2{
	text-align:center;
	z-index:1;
	position:relative;
}
.counter-style-2 .counter-bx{
	margin-bottom:10px;
}
.counter-style-2 .counter-bx span{
	font-size: 110px;
	font-weight: 300;
	line-height: 110px;
	display: inline-block;
	color: var(--primary);
}
.counter-style-2 .counter-text{
	font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #627696;
    text-transform: uppercase;
}
.counter-style-2 .bg-icon{
	color:#000;
	width: 100%;
	text-align: center;
	top: -40px;
	bottom: auto;
}
.text-white .counter-style-2 .counter-text,
.text-white .counter-style-2 .counter-bx span{
	color:#fff;
}
.counter-style-3{
    text-align: center;
    padding: 30px 10px;
    border-radius: 8px;
}
.counter-style-3 .counter-text{
    font-size: 14px;
    color: #fff;
}
.counter-style-3 div span{
    font-size: 50px;
    color: #fff;
    line-height: 60px;
    margin-bottom: 5px;
    display: inline-block;
}

/*========================
	12. FOOTER
=========================*/
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
    color: #b0b0b0;
}
footer p,
footer strong,
footer b,
footer {
    color: rgba(255,255,255,0.8);
}
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: rgba(255,255,255,0.8);
}

footer .btn-link,
footer a,
footer p a {
    color: rgba(255,255,255,0.6);
}
footer a:active,
footer a:focus,
footer a:hover {
    color: rgba(255,255,255,0.6);
}
/* widget color */
footer .widget_categories ul li a,
footer .widget_archive ul li a,
footer .widget_meta ul li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_nav_menu li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
    color: rgba(255,255,255,0.6);
}
footer.text-white .widget_categories ul li a, 
footer.text-white .widget_archive ul li a, 
footer.text-white .widget_meta ul li a, 
footer.text-white .widget_pages ul li a, 
footer.text-white .widget_recent_comments ul li a, 
footer.text-white .widget_nav_menu li a, 
footer.text-white .widget_recent_entries ul li a, 
footer.text-white .widget_services ul li a, 
footer.text-white a, 
footer.text-white .footer-bottom, 
footer.text-white p, 
footer.text-white strong, 
footer.text-white b, 
footer.text-white .widget .post-title, 
footer.text-white .widget-about .ttr-title, 
footer.text-white {
    color: rgba(255,255,255,1);
}
footer p {
    margin-bottom: 10px;
}
footer p,
footer li {
    font-size: 16px;
    line-height: 32px;
}
footer#footer {
    background-position: center;
    background-size: cover;
}
footer p {
    line-height: 32px;
    margin-bottom: 15px;
	color: rgba(255,255,255,0.6);
	font-weight: 400;
}
footer .footer_widget ul {
    list-style: none;
    margin-top: -10px;
	padding: 0;
}
/*widget li in footer*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
    border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}
footer .widget_services ul li {
	transition:all 1s;
	-ms-transition:all 1s;
	-o-transition:all 1s;
	-moz-transition:all 1s;
	-webkit-transition:all 1s;
	padding: 10px 0px 10px 15px;
}
footer .widget_services ul li:hover {
	transform: translateX(10px);
	-moz-transform: translateX(10px);
	-webkit-transform: translateX(10px);
	-o-transform: translateX(10px);
	-ms-transform: translateX(10px);
}
.footer-logo {
    width: 90px;
    display: block;
}
.footer-top {
    background-color:#0a0b0c;
	background-image:url(../images/pattern/pt2.png), url(../images/pattern/pt2.png);
    background-position: center;
    padding: 80px 0 40px;
}
.footer-top .widget {
    margin-bottom: 30px;
}
.footer-bottom {
    background-color:#303030;
    padding: 25px 0;
    color: #b0b0b0;
	border-top:1px solid rgba(255,255,255,0.05);
}
.footer-bottom ul {
    margin: 0;
}
.footer-title{
	margin-bottom: 20px;
	color: #fff;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 20px;
	position: relative;
}
.footer_widget ul li a{
	padding: 5px 0;
    display: block;
    font-weight: 400;
    line-height: 28px;
}
.ft-social-bx{
	margin-bottom:0;
}
.ft-social-bx a{
	margin-right: 0;
	color: #fff;
	width: 45px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	text-align: center;
	font-size: 14px;
}
.ft-social-bx a i{
	margin:0;
}
.list-2 ul{
	margin:0;
	padding:0;
	list-style:none;
	display:table;
	margin-top:-8px;
	margin-bottom:-8px;
}
.list-2 ul li{
	width:50%;
	float:left;
}
.list-2 ul li a{
    font-size: 16px;
    font-weight: 400;
    padding: 8px 0;
    display: block;
	line-height: 22px;
}
.footer_talkus p{
	margin-top: -10px;
}
.footer-innner-btm{
	display:flex;
	justify-content: space-between;
	border-top:1px solid rgba(255,255,255,0.1);
	padding: 25px 0;
}
.footer-innner-btm p{
	color:#fff;
	opacity:0.5;
	font-weight:400;
	font-size:14px;
	margin:0;
}
.footer-style2 .footer-top{
	background: #020d26;
	font-weight: 400;
}
.footer-style2 .footer-bottom{
	background: #020d26;
	font-size: 14px;
	color:rgba(255,255,255,0.6);
	font-weight: 400;
}
.footer-style2 .footer-title{
	font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.footer-style2 .footer_widget ul li a{
    font-weight: 400;
}
.footer-style2 .footer-bottom p{
	font-size: 14px;
    color: rgba(255,255,255,0.6);
    font-weight: 400;
	line-height:24px;
}
.footer-info-bx .ttr-tilte{
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
}
.footer-info{
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding-bottom: 40px;
    margin-top: -35px;
}
.btn-long{
	padding-left:40px;
	padding-right:40px;
}
.footer-info-bx p{
	font-size: 14px;
    line-height: 22px;
}
.link-inline{
	margin:0;
	padding:0;
	list-style:none;
}
.link-inline li{
	display:inline-block;
    line-height: 24px;	
}
.link-inline li a{
    color: rgba(255,255,255,0.6);
    font-weight: 400;
    padding-left: 23px;
	font-size: 14px;	
}
.footer-style2 .ft-social-bx a{
    border-radius: 40px;
    font-size: 16px;
    height: 40px;
    width: 40px;
    line-height: 40px;
}
.widget_info ul{
	margin:0;
	padding:0;
	list-style:none;
    margin-top: -10px;	
}
.widget_info ul li{
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 0;	
	margin: 0 3px;
}
.widget_info ul li strong{
	font-weight: 300;
    margin-right: 5px;
	color:rgba(255,255,255,0.6);
}

/* Subscribe Form */
.subscribe-form input{
	background-color: #fff;
    height: 50px;
    padding: 10px 25px;
    margin-right: 0;
    color: #495057;
    font-size: 15px;
    border-radius: 0 !important;
    border: 0;
}
.subscribe-form .btn{
	padding: 0 30px;
    border-radius: 0 !important;
	margin-left:10px;
}
/* subscribe box 2 */

.subscribe-box .subscribe-title{
	max-width: 300px;
	width: 300px;
}
.subscribe-box .subscribe-title h4{ 
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 0;
}
.subscribe-box .form-control {
	background-color:rgba(0,0,0,0);
	color:#fff;
	border-bottom:1px solid rgba(255,255,255,0.3);
	padding-left:0;
}
.subscribe-box .form-control::-moz-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control:-moz-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control:-ms-input-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control::-webkit-input-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .subscribe-form {
	width:100%;
	padding-left: 30px;
}
.subscribe-box{
	
}
.subscribe-box .btn{
	
}
.subscribe-action{
    max-width: 600px;
    margin: auto;
    padding: 10px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    border-radius: 60px;
	background: #fff;
}
.subscribe-action .form-control{
	border:0;
	padding:10px 30px;
	font-size:15px;
}
.bg-dark-gray{
	background-color:#3744a8;
}

/* Footer White */
.footer-white .footer-top.bt0{
	border-top:0;
}
.footer-white .footer-top,
.footer-white .footer-bottom {
	 background: #f2f2f2;
	 color:#272d3a;
	 border-top: 1px solid rgba(0,0,0,0.1);
}
.footer-white .footer-title{
	color:#000;
}
.footer-white .footer-title::after {
	width: 50px;
    background: var(--primary);
    opacity: 1;
    height: 2px;
}
.footer-white .footer-info-bx .ttr-tilte{
	color:#000;
}
.footer-white .footer-info{
	border-bottom:1px solid rgba(0,0,0,0.1);
}
footer.footer-white.footer-style2 .footer-bottom p, 
footer.footer-white .btn-link, 
footer.footer-white a, 
footer.footer-white p a,
footer.footer-white p, 
footer.footer-white strong, 
footer.footer-white b, 
footer.footer-white{
	color:#272d3a;
}
footer.footer-white .btn {
	color:#fff;
}
.pt-exebar {
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.pt-social-link,
.pt-btn-join{
	border-left: 1px solid rgba(0,0,0,0.1);
}
/*==== LOADING ====*/
#loading-icon-bx {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 999999999;
    background-image: url(../images/loading.svg);
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: center;
}
/*========================
	13. INNER CONTENT CSS
=========================*/
/* About Section */
.service-info-bx{
	margin-top: -215px;
}
.service-bx{
	box-shadow:0 0 25px 0 rgba(29,25,0,0.25);
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	position:relative;
	background-color:#fff;
}
.service-bx [class*="feature-"]{
	box-shadow: 0 0 25px 0 rgba(29,25,0,0.15);
	margin-top: -30px;
	position: relative;
	top: -40px;
	margin-bottom: -20px;
}
.service-bx .info-bx{
	padding:30px;
}
.service-bx:hover{
	transform:translateY(-15px);
	-moz-transform:translateY(-15px);
	-webkit-transform:translateY(-15px);
	-ms-transform:translateY(-15px);
	-o-transform:translateY(-15px);
}
.service-icon-bx{
	display: flex;
    align-items: center;
    max-width: 700px;
    margin: auto;
    padding: 50px 0;
    border-bottom: 2px dashed rgba(0,0,0,0.2);
	position:relative;
	z-index: 1;
}
.service-icon-bx .service-icon a{
	font-size: 150px;
    background: #e1e3ff;
    border-radius: 100%;
    height: 300px;
    width: 300px;
    display: inline-block;
    text-align: center;
    line-height: 300px;
	overflow:hidden;
}
.service-icon-bx .service-icon a img{
	height: 100%;
	vertical-align: top;
	object-fit: cover;
}
.service-icon-bx .service-info{
	padding: 0 0 0 50px;
	max-width: 400px;
    margin-left: auto;
}
.service-icon-bx:nth-child(2n){
	flex-direction: row-reverse;
}
.service-icon-bx:nth-child(2n) .service-info{
	padding: 0 50px 0 0;
}
.service-icon-bx:after{
    content: "";
    width: 110px;
    height: calc(100% + 4px);
    position: absolute;
    bottom: -2px;
    border-radius: 120px 0 0px 120px;
    border: 2px dashed rgba(0,0,0,0.2);
    left: -110px;
    border-width: 2px 0 2px 2px;
    z-index: -1;
}
.service-icon-bx:first-child:after{
	height: 220px;
    left: -110px;
    border-radius: 110px 0 0 110px;
    border-width: 2px 0px 2px 2px;
    right: auto;
}
.service-icon-bx:nth-child(2n):after{
	height: calc(100% + 4px);
    left: auto;
    border-radius: 0 110px 110px 0;
    border-width: 2px 2px 2px 0px;
    right: -110px;
}
.service-icon-bx:last-child:after{
    left: auto;
    right: -110px;
    bottom: auto;
    top: -2px;
    border-radius: 0 120px 120px 0;
    border-width: 2px 2px 2px 0px;
	height: 220px;	
}
.service-icon-bx:nth-child(2n) + .service-icon-bx:last-child:after{
	right: auto;
    left: -110px;
    bottom: auto;
    top: -2px;
    border-radius: 120px 0 0 120px;
    border-width: 2px 0px 2px 2px;
}
.service-icon-bx .service-icon{
	position:relative;
}
.service-icon-bx .service-icon:after{
	content: attr(data-name);
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    line-height: 50px;
    top: 50%;
    right: -30px;
    width: 60px;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    height: 60px;
    background: var(--primary);
    border-radius: 100%;
    text-align: center;
    color: #fff;
    border: 5px solid #fff;
}
.service-icon-bx:nth-child(2n) .service-icon:after{
	right: auto;
	left:-30px;
}
.service-icon-bx:first-child{
	padding-top: 40px;
}
.service-icon-bx:last-child{
	border: 0;
    padding-bottom: 10px;
}
.service-list{
	margin:0;
	padding:0;
	list-style:none;
	border: 1px solid rgba(0,0,0,0.15);
    background-color: white;
    border-radius: 4px;
}
.service-list li a{
	background: #fff;
    display: block;
    padding: 12px 50px 12px 20px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    color: #020d26;
    font-weight: 500;
    font-size: 16px;
}
.service-list li:last-child a{
	border-bottom:0;
}
.service-list li.active a{
    background-color: var(--primary);
    color: #fff;
}
.service-list li a i{
	width: 26px;
    height: 26px;
    background: #eee;
    color: #000;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    border-radius: 35px;
    position: absolute;
    right: 20px;
    top: 10px;
    padding-left: 2px;
}
.service-list li a:after{
	content: "";
    height: 100%;
    background-color: var(--primary);
    width: 0;
    position: absolute;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    z-index: -1;
    top: 0;
    transform-origin: right;
    -moz-transform-origin: right;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
}
.service-list li:hover a{
	color: #fff;
}
.service-list li:hover a:after{
	 transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    width: 100%;
    right: auto;
    left: 0;
}
.service-list.style-2{
	background: transparent;
    border: 0;
}
.service-list.style-2 li a{
	border: 0;
    background: transparent;
    color: #555;
    font-size: 18px;
    font-weight: 500;
	padding: 7px 0 7px 40px;
}
.service-list.style-2 li a i{
    left: 0;
    width: 24px;
    height: auto;
    background: transparent;
    font-size: 24px;
    top: 7px;
    color: #555;
    padding: 0;
}
.service-list.style-2 li.active a i,
.service-list.style-2 li.active a,
.service-list.style-2 li:hover a i,
.service-list.style-2 li:hover a{
	color:var(--primary);
}
.service-list.style-2 li a:after{
	content: "\f30b";
    font-family: 'Line Awesome Free';
    width: auto;
    height: auto;
    background: transparent;
    color: var(--primary);
    font-weight: 900;
    font-size: 24px;
    right: 50%;
    opacity: 0;
}
.service-list.style-2 li.active a:after,
.service-list.style-2 li:hover a:after{
	right: 30px;
	opacity:1;
	    left: auto;
}
/* service-box */
.service-gallery-top .service-info .service-content,
.service-thumb:after,
.service-thumb,
.service-box .service-inner:after,
.service-box{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.service-box{
	box-shadow: 0 10px 30px 0 rgba(0,0,0,0.15);
	border-bottom: 3px solid var(--primary);
}
.service-box:hover{
	transform:translateY(-10px);
	-moz-transform:translateY(-10px);
	-ms-transform:translateY(-10px);
	-webkit-transform:translateY(-10px);
	-ms-transform:translateY(-10px);
}
.service-box .service-media{
	position:relative;
}
.service-box .service-info{
	padding: 30px;
}
.service-box .service-info .title{
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}
.service-box .service-info p{
	margin-bottom: 0;
	line-height: 24px;
}
.service-box .service-inner{
	position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
	z-index: 1;
}
.service-box .service-inner:after{
	background: var(--primary);
	position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
	content:"";
	opacity:0.9;
	z-index: -1;
	opacity:0;
}
.service-box:hover .service-inner:after{
	height:100%;
	opacity:0.9;
}
.service-box .service-inner .btn{
	transform: scaleY(0);
	-moz-transform: scaleY(0);
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
    -moz-transform-origin: bottom;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
	transition:all 0.4s ease 0.2s;
	-moz-transition:all 0.4s ease 0.2s;
	-ms-transition:all 0.4s ease 0.2s;
	-webkit-transition:all 0.4s ease 0.2s;
	-ms-transition:all 0.4s ease 0.2s;
	opacity:0;
}
.service-box:hover .service-inner .btn{
	transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	-webkit-transform: scaleY(1);
	opacity:1;
}
.service-gallery-top .service-info{
    height: 650px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
}
.service-gallery-top .service-info .container{
	position:relative;
	z-index:1;
}
.service-gallery-top .service-info .service-content .ttr-tilte{
	margin-bottom:10px;
}
.service-gallery-top .service-info .service-content{
	background: #fff;
    padding: 30px 40px;
    width: 450px;
    margin-bottom: 150px;
    position: relative;
    z-index: 1;
	opacity:0;
}
.service-gallery-top .slick-active .service-info .service-content{
	opacity:1;
}
.service-gallery-top .service-info .service-content p{
	font-size:18px;
	color:#585858;
}
.service-gallery-top .service-info .service-content .bg-icon{
	color: #000;
    line-height: 200px;
	right:20px;
}
.service-gallery-top .service-info:after{
	position:absolute;
	content:"";
	height:100%;
	width:100%;
	top:0;
	left:0;
	background:#000;
	opacity:0.3;
}
.swiper-service{
	position:relative;
}
.swiper-thumbs-box{
	z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background: rgba(0,0,0,0.2);
}
.service-thumb{
	padding:25px 5px;
	position: relative;
    z-index: 1;
	
}
.service-thumb:after{
	content: "";
    width: 15px;
    height: 15px;
    background: #000;
    position: absolute;
    transform: rotate(45deg) translateX(-50%);
    bottom: -10px;
    left: 50%;
	opacity:0;
}
.service-thumb .title{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.service-thumb .title i{
	font-size: 30px;
    line-height: 30px;
    margin-right: 10px;
}
.swiper-slide-thumb-active .service-thumb{
	background: var(--primary);
}
.left-border-1{
	padding-left: 30px;
    border-left: 1px solid #c2c2c2;
}
.brochure-bx{
    padding: 20px 20px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border: 1px solid rgba(0,0,0,0.15);
}
.brochure-bx .title-head {
	border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-bottom: 8px;
    position: relative;
    margin-bottom: 30px;	
}
.brochure-bx .title-head:after {
	background:var(--primary);
	content:"";
	width:30px;
	height:3px;
	position:absolute;
	bottom:-2px;
	left:0;
}
.brochure-bx:after{
	z-index: -1;
    content: "\f100";
    font-family: "Flaticon";
    position: absolute;
    color: #fff;
    font-size: 200px;
    bottom: 0;
    line-height: 200px;
    opacity: 0.1;
}
.download-link{
    position: relative;
    display: block;
    padding-left: 45px;	
    margin-top: 20px;	
}
.download-link .title{
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 22px;
}
.download-link span{
	color: #888;
    font-size: 14px;
    line-height: 18px;	
}
.download-link img{
    width: 45px;
    position: absolute;
    left: -5px;
    top: 0;
}
.testimonial-carousel .owl-stage{
	padding-left: 0px !important;
}
.testimonial-carousel .owl-nav{
	position: absolute;
    top: -80px;
    left: 0;
	text-align: right;
	max-width: 1140px;
	    width: 100%;
}
.owl-nav.disabled{
	display:none;
}
.help-bx{
	background:var(--primary)
}
.help-bx .info{
    padding: 15px 25px 35px 25px;
    text-align: center;	
	position:relative;
	z-index:1;
}
.help-bx .title{
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
}
.help-bx p{
	font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}
.help-bx .info img{
    max-width: 60px;
    margin-bottom: 20px;
}
.help-bx .info:before,
.help-bx .info:after{
	content: "";
    background: var(--primary);
    position: absolute;
    top: -25px;
    width: 100%;
    height: 50px;
    left: 0;
    transform: skewY(-8deg);
	z-index:-1;
}
.help-bx .info:before{
	background: #fff;
    top: -32px;
    transform: skewY(-5deg);
    height: 40px;
}










.testimonial-carousel.owl-btn-1 .owl-prev, 
.testimonial-carousel.owl-btn-1 .owl-next{
	line-height:50px;
	height:50px;
	width:50px;
	border-radius:50px;
	background-color:var(--sc-primary);
	color:#000;
	margin: 0 5px !important;
}
.testimonial-carousel .owl-nav .owl-next:hover,
.testimonial-carousel .owl-nav .owl-prev:hover{
	background-color:var(--primary);
	color:#fff;
}
.testimonial-carousel-2 .owl-dots{
	text-align:center;
	margin-top:15px;
}
.testimonial-carousel-2 .owl-dots .owl-dot{
	width: 15px;
	height: 4px;
	background: #000;
	display: inline-block;
	margin: 0 4px;
	cursor:pointer;
	border-radius:2px;
	opacity: 0.2;
}
.testimonial-carousel-2 .owl-dots .owl-dot.active{
	opacity:1;
	background:var(--primary)
}
/* heading-bx */
.heading-bx.left .title-head{
	margin-bottom: 10px;
    margin-top: 0;
    line-height: 32px;
    padding-left: 10px;
    border-left: 5px solid var(--primary);
}
.heading-bx.left p{
	max-width:500px;
	margin-bottom:0;
}
.title-head span{
	font-weight:400;
}
.heading-bx.style1 .title-head{
	color:#3f3e85;
	font-size:40px;
	line-height:50px;
	font-weight: 700;
	margin-bottom:0;
}
.heading-bx.style1 p{
	color:#6c7079;
	margin-bottom: 0;
}
.heading-bx.style1.text-white .title-head{
	color:#fff;
}
.heading-bx.style1.text-white p{
	color:#fff;
	opacity:0.7;
}

/* section space */
.section-sp1{
	padding-top:100px;
	padding-bottom:70px;
}
.section-sp2{
	padding-top:100px;
	padding-bottom:100px;
}
.section-sp3{
	padding-top:100px;
	padding-bottom:0;
}
.section-sp4{
	padding-top:50px;
	padding-bottom:50px;
}
/* Event Form */
.event-form{
	background:var(--primary);
	padding: 30px 30px;
	border-radius: 4px;	
}
.event-form.contact-bx textarea.form-control{
	height: 150px;
}
.event-form .heading-bx {
    margin-bottom: 20px;
}
.event-form .btn-secondry:active, 
.event-form .btn-secondry:hover, 
.event-form .btn-secondry:focus{
	background-color:#181b4a;
}


/* Time Line */
.timeline-nav{
	top: 150px;
}
.timeline-nav ul{
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;
	text-align:right;
}
.timeline-nav ul li{
	display:block;
	width: 100%;
}
.timeline-nav ul li a{
	display: block;
	width: 100%;
	font-size: 22px;
	font-weight: 700;
	color: var(--primary);
	opacity: 0.3;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	padding: 0 0;
	text-decoration:underline;
	padding: 5px 0;
}
.timeline-nav ul li a.active{
	opacity: 1;
	font-size: 44px;
	color: var(--primary);
	padding: 10px 0;
}
.timeline-box{
	margin-bottom:60px;
}
.timeline-box p{
    font-size: 18px;
    line-height: 32px;
    color: #404153;
}

/* Time Line 2 */
.timeline-view {
	position:relative;
    max-width: 1000px;
    margin: auto;	
}
.timeline-view:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25%;
    margin-left: 0px;
    border-left: 2px dashed #ccc;
}
.timeline-area {
	position: relative;
	background-color: inherit;
	width: 100%;
	left: 0; 
	display: flex; 
}
.timeline-area:last-child .timeline-content{
	border:0;
	margin-bottom:0;

}
.timeline-content{
    max-width: 75%;
    flex: 0 0 75%;
    padding-left: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 30px;
    margin-bottom: 50px;
}
.timeline-content p{
    font-size: 18px;
    color: #777;
    margin-bottom: 15px;
}
.timeline-date .date-bx{
    font-weight: 600;
    color: #000;
    font-size: 40px;
    line-height: 30px;
    padding: 10px 0 20px 0;
    border-radius: 4px;
    position: relative;
    width: 100%;
}
.timeline-date .date-bx:before {
	content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: -35px;
    background-color: white;
    border: 5px solid var(--primary);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}
/* .timeline-date .date-bx:after{
	content: "";
    width: 10px;
    height: 10px;
    background: var(--primary);
    position: absolute;
    right: -4px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 18px;
} */
.timeline-date{
    max-width: 25%;
    flex: 0 0 25%;
    padding-bottom: 80px;
    padding: 0 25px 50px 0;
}
.timeline-date h6{
	font-size: 16px;
    color: #777;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0px;		
}
.timeline-date h4{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
}
.timeline-date p{
    line-height: 24px;
    font-size: 16px;
    color: #888;
}














@media screen and (max-width: 600px) {
	.timeline-view:after {
	  left: 31px;
	}
	.timeline-area {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	    flex-direction: column;	
	}
	.timeline-area:before {
		left: 60px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}
	.timeline-view .left:after, 
	.timeline-view .right:after {
		left: 15px;
	}
	.timeline-view .right {
		left: 0%;
	}
	.timeline-date .date-bx:before,
	.timeline-view:after{
		content:none;
	}
	.timeline-content{
		padding-bottom: 10px;
		margin-bottom: 30px;
	}
	.timeline-date,
	.timeline-content{
	    padding-left: 0;	
		max-width: 100%;
		flex: 0 0 100%;
	}
	.timeline-date{
		padding: 0;	
	}
}
/* award */
.award-bx{
	padding:30px;
	background:#fff;
	box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
	position:relative;
}
.award-bx .award-media{
    border: 2px dashed rgba(0,0,0,0.2);
    padding: 5px;
	position:relative;
}
.award-bx .award-media:before,
.award-bx .award-media:after{
	content:"";
	position:absolute;
	transition:all 0.5s;
	width:0;
	height:0;
	opacity:0;
}
.award-bx .award-media:before{
	top:-2px;
	left:-2px;
	border-left:4px solid var(--primary);
	border-top:4px solid var(--primary);
}
.award-bx .award-media:after{
	bottom:-2px;
	right:-2px;
	border-right:4px solid var(--primary);
	border-bottom:4px solid var(--primary);
}
.award-bx:hover .award-media:before,
.award-bx:hover .award-media:after{
	width:50%;
	height:50%;
	opacity:1;
}
.award-bx .award-info{
	text-align:center;
	padding-top:20px;
	
}
.award-bx .award-info .title{
    font-size: 24px;
    font-weight: 600;
    MARGIN-BOTTOM: 10PX;
}
.award-bx .award-info p{
    font-size: 18px;
    font-weight: 400;
    color: #777;
    margin-bottom: 0;
}
.bx-hover-up{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.bx-hover-up:hover{
	transform:translateY(-15px);
}
.award-bx.left{
	display: flex;
	align-items: flex-start;
}
.award-bx.left .award-media {
    min-width: 30%;
}
.award-bx.left .award-info {
    text-align: left;
    margin-left: 20px;
    padding-top: 0;
}
/* Job Career */
.job-career-box {
    padding: 30px;
    background: #fff;
    box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
}
.job-career-box p{
	font-size:18px;
	color:#666;
	margin-bottom:10px;
}
.job-career-box ul li{
	font-size:18px;
	color:#666;
}
.job-career-box .list-check li:before{
	top: 8px;
}
.job-career-box p strong{
	font-weight:600;
	color:#111;
}

/* Testimonials */
.testimonial-bx{
	background-color: #fff;
	padding:30px;
	border-radius: 4px;
	position:relative;
	overflow:hidden;
}
.testimonial-bx.shadow{
	box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
}
.testimonial-bx .testimonial-content p,
.testimonial-bx .testimonial-info p{
	margin: 0;
}
.testimonial-bx .testimonial-info h5{
	font-weight: 600;
	margin-bottom: 0;
	color: var(--primary);
}
.testimonial-bx .testimonial-info {
	margin-top: 15px;
}
.testimonial-bx .testimonial-info:after {
    content: "\f10e";
    position: absolute;
    font-family: fontawesome;
    font-size: 60px;
    color:var(--primary);
    right: 25px;
    bottom: 0;
	z-index: 9;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.testimonial-thumb {
    width: 80px;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 0 0 8px 5px rgba(0,0,0,0.1);
    position: absolute;
    left: 0;
	z-index: 9;
}
.testimonial-bx:hover .testimonial-info:after {
	opacity:0;
}
.testimonial-bx .testimonial-content p{
	color:#303030;
	font-size:16px;
}

.testimonial-bx .testimonial-pic {
	background: #FFF;
	width: 80px;
	height: 80px;
	position: absolute;
	right: -100px;
	border-radius: 100%;
	overflow: hidden;
	bottom: -100px;
	z-index: 10;
	border: 3px solid #fff;
	box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.15);
	transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	display: block;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.testimonial-bx:hover .testimonial-pic {
    right: 20px;
    bottom: 20px;
	transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}
.case-studies,
.our-partner,
.team-area,
.news-area{
	position:relative;
	z-index:2;
}
.our-partner{
	z-index:3;
}
.news-area{
	overflow:hidden;
}
.news-area:before {
    content: attr(data-name);
    font-size: 160px;
    line-height: 160px;
    left: 0;
    letter-spacing: 10px;
    position: absolute;
    top: 60px;
    z-index: -1;
    font-weight: 700;
	text-shadow:0 5px 40px #edf4ff;	
    color: #eef2f8;
	-webkit-animation:15s scrollup infinite linear;
	-moz-animation:15s scrollup infinite linear;
	-o-animation:15s scrollup infinite linear;
	-ms-animation:15s scrollup infinite linear;
	animation:15s scrollup infinite linear;
	white-space: nowrap;
}
.case-studies:before,
.our-partner:before,
.team-area:before {
	content: attr(data-name);
	font-size: 220px;
	line-height: 210px;
	left: 0;
	letter-spacing: 10px;
	position: absolute;
	bottom: -28px;
	z-index: -1;
	font-weight: 700;
	text-shadow: 0 5px 40px #edf4ff;
	color: #fff;
	width: 100%;
	text-align: center;
}

.our-partner:before{
	text-align:left;
	bottom:auto;
	top:0;
}
.case-studies:before{
	text-align:center;
	bottom:auto;
	top:-20px;
}
/* Image Move Css */
/* @-webkit-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-moz-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-o-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-ms-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
} */
@keyframes scrollup{
	100%{
		transform:translateX(-3000px);
	}
}


/* testimonial-bx style1 */
.testimonial-bx.style1{
	background: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    overflow: unset;
    max-width: 450px;
}
.testimonial-bx.style1:after{
    content: "";
    background: #fff;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 60px;
    bottom: -8px;
    transform: skew(20deg, -30deg);
}
.active .testimonial-bx.style1{
	opacity:0.3;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	-ms-transition:all 0.5s;
}
.center.active .testimonial-bx.style1{
	opacity:1;
}
.clinet-thumbs .testimonial-thumb {
	width: 80px;
    overflow: hidden;
    border: 0;
    box-shadow: none;
    position: unset;
    left: 0;
    z-index: 9;
    height: 80px;
    border-radius: 80px;
    opacity: 0.7;
	transform: scale(0.90);
	-moz-transform: scale(0.90);
	-webkit-transform: scale(0.90);
	-ms-transform: scale(0.90);
	-o-transform: scale(0.90);
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	cursor:pointer;
}
.clinet-thumbs .swiper-slide{
    width: auto !important;
}
.clinet-thumbs .swiper-slide-thumb-active .testimonial-thumb {
	transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	opacity: 1;
}
.testimonial-bx.style1 .testimonial-content p{
	color:#424141;
	font-size:20px;
	line-height:30px;
}
.testimonial-bx.style1 .testimonial-info .name{
	font-size:24px;
	margin-bottom:5px;
	font-weight:500;
}
.testimonial-bx.style1 .testimonial-info p{
	color:var(--primary);
	font-weight:500;
}
.testimonial-bx.style1 .testimonial-info:after{
	content:none;
}
.testimonial-bx.style2{
	transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	outline: 0;
}
.testimonial-bx.style2.blue{
	background:#f5f5ff;
}
.testimonial-bx.style2.orange{
	background:#fff9f3;
}
.testimonial-bx.style2.red{
	background:#fff3f3;
}
.testimonial-bx.style2.red .testimonial-info:after{
    background: #fc6765;
    box-shadow: 10px 10px 50px 0px #fc6765;
	color: #fff;
}
.testimonial-bx.style2.orange .testimonial-info:after{
    background: #ffa143;
    box-shadow: 10px 10px 50px 0px #ffa143;
	color: #fff;
}
.testimonial-bx.style2.blue .testimonial-info:after{
    background: #675fff;
    box-shadow: 10px 10px 50px 0px #675fff;
	color: #fff;
}
.testimonial-bx.style2:before,
.testimonial-bx.style2:after{
	content:none;
}
.testimonial-bx.style2 .title{
	margin-bottom:10px;
}
.testimonial-rating{
	color:#ffb944;
	margin-bottom: 20px;
}
.testimonial-bx.style2 .testimonial-thumb{
	position: absolute;
	width: 60px;
	border: 0;
	left:0;
    box-shadow: none;
	border-radius: 40px;
}
.testimonial-bx.style2 .client-info{
	position:relative;
	padding-left:80px;
	margin-top: 30px;
}
.testimonial-bx.style2 .client-info .name{
	font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
}
.testimonial-bx.style2 .testimonial-info{
	margin-top: 0;
	padding: 5px 0;
}
.testimonial-bx.style2 .testimonial-info p{
	font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 600;
    color: #6d747e;
}
.testimonial-bx.style2 .testimonial-info:after{
    font-size: 20px;
    right: 0;
    bottom: 0;
    background: #ffede4;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
}
.testimonial-bx.style2:hover .testimonial-info:after{
	opacity:1;
}
.testimonial-bx.style2:hover{
	transform:translateY(-10px);
}
.client-appoint-box .ajax-form{
	padding-left:15px;
	position:relative;
}
.client-appoint-box .appoint-bx1 .input-bx:after{
	background:var(--primary);
}
.apoint-btn-box{
	background: #03204a;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    position: absolute;
    left: -178px;
    top: 50%;
    transform: rotate(-90deg) translateX(0%);
    -moz-transform: rotate(-90deg) translateX(0%);
    -webkit-transform: rotate(-90deg) translateX(0%);
    -ms-transform: rotate(-90deg) translateX(0%);
    -o-transform: rotate(-90deg) translateX(0%);
    padding: 15px 30px;
}
.apoint-btn-box:before,
.apoint-btn-box:after{
	content: "";
    background: #03204a;
    position: absolute;
    height: 100%;
    width: 40%;
    transform: skewX(40deg);
    -moz-transform: skewX(40deg);
    -ms-transform: skewX(40deg);
    -webkit-transform: skewX(40deg);
    -o-transform: skewX(40deg);
    top: 0;
    right: -30px;
    z-index: -1;
}
.apoint-btn-box:before{
	right: auto;
    left: -30px;
	transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -o-transform: skewX(-40deg);
}
.client-appoint-box .container,
.client-appoint-box {
	position:relative;
	z-index:1;
}
.client-appoint-box:after{
	content: "";
    z-index: -1;
    width: 50%;
    right: 0;
    background: #020d26;
    height: 100%;
    top: 0;
    position: absolute;
}
/* testimonial-bx style1 END */
.ovbl-middle:after,
.ovbl-light:after,
.ovbl-dark:after{
	background: #000;
}
.our-story{
	position:relative;
	z-index: 1;
}
.our-story:after{
	width:50%;
	content:"";
	height:100%;
	position:absolute;
	left:50%;
	background-color:#fff;
	top:0;
	z-index: -1;
}
.our-story .style1 p{
	margin-bottom:20px;
}
.our-story .heading-bx.style1 .title-head{
	margin-bottom:10px;
}
.video-bx{
	position:relative;
}
.video-bx .video-media-bx a{
	width:80px;
	height:80px;
	border-radius:80px;
	line-height:80px;
	font-size:24px;
}
.counter-style-1 span{
	font-size:70px;
	font-weight:700;
	line-height: 70px;
	display: inline-block;
}
.counter-style-1 .counter-text{
	color: #fff;
	font-size: 18px;
	line-height: 40px;
}
.join-content-bx{
	max-width:800px;
	margin:auto;
}
.join-content-bx h2{
	font-size: 45px;
	line-height: 65px;
	margin: 0px 0 20px 0;
}
.join-content-bx h4{
	font-weight:400;
	font-size:30px;
}
.join-content-bx p{
	line-height:24px;
	opacity: 0.8;
}
.choose-bx .choose-bx-in{
	margin-top:-20px;
}

.mvimg{
	width: 50vw;
	max-width: 50vw;
	height: 100%;
	object-fit: cover;
}
/* MemberShip */
.pricingtable-inner {
	text-align: center;
	overflow: hidden;
	background:	#fff;
}
.pricingtable-features,
.pricingtable-features li,
.pricingtable-footer{
	border:0;
}
.pricingtable-features li{
	color: #000;
    font-size: 18px;
    font-weight: 400;
}
.pricingtable-bx {
    font-size: 70px;
    font-weight: 600;
    line-height: 70px;
    color: #000;
}
.priceing-doller {
	vertical-align: top;
    font-size: 24px;
    line-height: 24px;
    color: var(--primary);
    font-weight: 700;
    position: relative;
    top: 13px;
}
.pricingtable-features{
	text-align:left;
    padding: 20px 0;	
}
.pricingtable-type{
    color: #a3a3a3;
    font-weight: 600;
}
.pricingtable-type::before{
	content: "/";
	margin-right: 5px;
	color:#a3a3a3;
}
.pricingtable-price{
	padding: 30px 20px;
	background-color: transparent;
	border: 1px solid rgba(0,0,0,0.1);
	border-width:0 0 1px 0;
}
.pricingtable-title {
	padding:18px 10px 28px 10px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	position: relative;
}
.pricingtable-title h2{
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	color:#000;
}
.pricingtable-title p{
	font-size: 13px;
	line-height: 20px;
	font-weight: 600;
	display: inline-block;
	background: var(--primary);
	padding: 2px 15px;
	position: absolute;
	bottom: -13px;
	white-space: nowrap;
	left: 50%;
	transform: translateX(-50%);
}
.pricingtable-footer{
	border-top: 1px solid rgba(0,0,0,0.1);
}
.pricingtable-footer .btn{
	font-size:16px;
	padding: 10px 30px;
}
.pricingtable-features li{
	padding: 5px 40px;
}
.pricingtable-features li i{
	color:var(--primary);
}
/* Profile Box */
.profile-bx{
	border:1px solid #e9e9e9;
	position:sticky;
	top:100px;
}
.user-profile-thumb{
	width:100px;
	height:100px;
	overflow:hidden;
	border-radius:100px;
	border: 5px solid #fff;
	box-shadow:0 0 15px 0 rgba(0,0,0,0.2);
	margin:25px auto 15px; 
}
.profile-info{
	margin-bottom:15px;
}
.profile-info h4{
	font-size:18px;	
	margin-bottom: 0;
}
.profile-info span{
	font-size:14px;
	color:#717171;
}
.profile-social ul li a{
	width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    border: 1px solid #e9e9e9;
    line-height: 34px;
    border-radius: 40px;
    color: #000;
    font-size: 14px;
}
.profile-social{
	border-bottom: 1px solid #e9e9e9;
	padding-bottom:15px;
}
.profile-content-bx{
	border: 1px solid #e9e9e9;
}
.profile-tabnav{ 
	margin:20px 0;
}
.profile-tabnav .nav{ 
	border:0;
}
.profile-tabnav .nav li{  
	display:block;
	width:100%;
}
.profile-tabnav .nav li a{
	border:0;
	color: #8a8a8a;
	font-size: 15px;
	text-align: left;
	padding: 10px 30px;
}
.profile-tabnav .nav li a.active{
	background-color: var(--primary);
	color: #fff;
	border-radius: 0;
}
.profile-tabnav .nav li a i{
	margin-right:10px;
}
.profile-head h3{
	font-size:20px;
	text-transform:uppercase;
	margin-bottom:0;
	font-weight:500;
}
.profile-head{
	display:flex;
	padding: 10px 15px 10px 30px;
	border-bottom: 1px solid #e9e9e9;
	align-items: center;
}
.profile-head .feature-filters{
	margin-bottom:0;
}
.courses-filter .action-card{
	margin-bottom:30px;
}
.courses-filter{
	padding:30px 30px 0 30px;
}
.profile-head .feature-filters.style1 li a{
	font-weight: 400;
	font-size: 15px;
}
.edit-profile h3{
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}
.edit-profile .help{
	font-size:12px;
	line-height:18px;

	display: block;
}
.edit-profile .col-form-label{
	font-size:14px;
	font-weight: 400;
}
.edit-profile{
	padding:30px;
}
.edit-profile .form-group {
	margin-bottom: 15px;
}
/* My Account */
.account-form{
	display:flex;
	width: 100%;
	position: relative;
}
.account-head{
	position: sticky;
	left:0;
	top:0;
	z-index: 1;
	width: 500px;
	min-width: 500px;
	height: 100vh;
	background-position: center;
	text-align: center;
	align-items: center;
	display: flex;
	vertical-align: middle;
}
.account-head a{
	display:block;
	width:100%;
}
.account-head:after{
	opacity:0.9;
	content:"";
	position:absolute;
	left:0;
	top:0;
	z-index:-1;
	width:100%;
	height:100%;
	background: #ff5e14; /* Old browsers */
	background: -moz-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #ff5e14 0%,#ff8e14 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #ff5e14 0%,#ff8e14 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5e14', endColorstr='#ff8e14',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.account-form-inner {
    width: 100%;
	align-self: center;
}
.account-container {
    max-width: 400px;
	margin: auto;
	padding: 30px 0;	
}
.custom-control-label:before{
	width: 20px;
	height: 20px;
	background-color: transparent;
	border: 2px solid var(--primary);
	top: 2px;
}
.custom-control-label:after{
	width: 20px;
	height: 20px;
	top: 2px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
	background-color: var(--primary);
}
.account-container .form-forget{
	display:flex;
}
.account-container .form-forget .custom-control-label{
	font-weight:400;
}
.account-container .btn.button-md{
	padding: 12px 40px;
}
.account-container .form-control{
	border-color: rgba(0,0,0,0.2);
}
.account-container .facebook i,
.account-container .google-plus i{
	border-right:1px solid #fff;
	padding-right:10px;
	margin-right:10px;
}
.account-form .heading-bx p a{
	color: var(--primary);
	text-decoration: underline;
	padding-bottom: 0px;
}
/* .g-recaptcha {
  transform-origin: left top;
} */
/* Google Recaptcha */
 
.g-recaptcha,
#rc-imageselect {
    transform:scale(0.99);
    -webkit-transform:scale(0.99);
    -moz-transform:scale(0.99);
    -o-transform:scale(0.99);
    
    transform-origin:0 0;
    -o-transform-origin:0 0;
    -moz-transform-origin:0 0;
    -webkit-transform-origin:0 0;
}
@media screen and (max-width: 575px){
	#rc-imageselect, 
	.g-recaptcha {
        transform:scale(0.77);
        -moz-transform:scale(0.77);
        -o-transform:scale(0.77);
        -webkit-transform:scale(0.77);
        
        transform-origin:0 0;
        -moz-transform-origin:0 0;
        -o-transform-origin:0 0;
        -webkit-transform-origin:0 0;
    
    }
}
.recaptcha-box{
 height:60px;
}
.g-recaptcha > div{
	width:250px !important; 
}
/* Google Recaptcha */
/* error */
.error-page{
	text-align:center;
}
.error-404{
	height: 100vh;
}
.error-404 .account-container {
	max-width: 700px;
}
.error-title{
	font-size: 250px;
    line-height: 250px;
    margin-bottom: 0;
	font-weight: 700;
	color: #06002a;
	display: inline-flex;
}
.error-title span:last-child {
    transform: rotateX(180deg) translateY(10px);
    -moz-transform: rotateX(180deg) translateY(10px);
    -webkit-transform: rotateX(180deg) translateY(10px);
    -ms-transform: rotateX(180deg) translateY(10px);
    -o-transform: rotateX(180deg) translateY(10px);
}
.error-page h3{
	font-size: 80px;
	line-height: 80px;
	color: #716e84;
}
.error-page h5{
	font-size: 22px;
	line-height: 30px;
	color: #716e84;
    font-weight: 500;
	margin-bottom: 30px;
}
.error-page p{
	font-size:16px;
	line-height:26px;
}
.why-chooses-bx .faq-bx{
	max-width: 700px;
	margin: auto;
}
.why-chooses-bx .faq-bx .panel {
	background:#fff;
	margin-bottom: 6px;
}
/* Services Box */
.services-bx:hover:after{
	background: #0066ff; /* Old browsers */
	background: -moz-linear-gradient(top, #0066ff 0%, #6021ff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #0066ff 0%,#6021ff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #0066ff 0%,#6021ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0066ff', endColorstr='#6021ff',GradientType=0 ); /* IE6-9 */
}
.services-bx .ttr-tilte,
.service-no,
.services-bx p,
.services-bx,
.services-bx:after{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.services-bx:after{
	content: "";
	position: absolute;
	height: 100%;
	bottom: -100%;
	width: 100%;
	left: 0;
	z-index: -1;
	border-radius:100%;
}
.services-bx:hover:after{
	border-radius:0;
	bottom: 0;
	border-radius:0;
}
.services-bx:hover{
	transform:translateY(-15px);
	-moz-transform:translateY(-15px);
	-o-transform:translateY(-15px);
	-webkit-transform:translateY(-15px);
	-ms-transform:translateY(-15px);
}
.service-no {
	line-height: 70px;
    font-size: 70px;
    color: transparent;
    position: absolute;
    top: 40px;
    right: 40px;
    font-weight: 500;
	background-clip: text;
	color:#393dff;
}
.services-bx{
	box-shadow: 0 10px 30px 0 rgba(8,0,255,0.1);
	padding: 50px;
	position: relative;
	z-index: 1;
	overflow:hidden;
	background: #fff;
}
.services-bx p{
	font-size:14px;
	line-height:28px;
	color:#69677b;
}
.services-bx .ttr-tilte{
	font-size:20px;
	color:#000;
}
.services-bx .feature-lg i{
	line-height:60px;
}
.services-bx .readmore{
	color:#000;
}
.services-bx .feature-lg .icon-cell{
	color:#000;
}
.services-bx .feature-lg{
	text-align:left;
}
.services-bx:hover .ttr-tilte{
	color:#fff;
}
.services-bx:hover p{
	color:#fff;
	opacity:0.8;
}
.services-bx:hover .feature-lg .icon-cell,
.services-bx:hover .readmore{
	color:#fff;
}
.services-bx:hover .service-no{
	color:#83ffbe;
}
/* Appointment */
.appointment-box .container{
	z-index: 2;
}
.appointment-box .appoint-bg{
	position: absolute;
	z-index: 1;
	width: 100%;
	bottom: 0;
}
.appointment-box .contact-bx{
	border-radius: 20px;
	padding: 50px;
	box-shadow: 0 0 30px 0 rgba(0, 86, 106, 0.15);
	max-width: 800px;
	margin: auto;
	background: #fff;
	background-image: url(../images/background/bg5.jpg);
	background-size: 100%;
}
.appointment-box .contact-bx .input-group label{
	color: #000;
}
.appoint-bx1 .form-control{
    background: transparent;
    border-width: 0 0 2px 0px;
    color: #fff;
    font-size: 16px;
    padding: 10px 0;
}
.appoint-bx1 .form-control::-moz-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control:-moz-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control:-ms-input-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control::-webkit-input-placeholder {
    color: #fff;
}
.appoint-bx1 .input-bx{
	position: relative;
	z-index:1;
}
.appoint-bx1 .input-bx:after{
	content: "";
    width: 0;
    height: 2px;
    position: absolute;
    background: #020d26;
    bottom: 0;
    right: 0;
    transform-origin: right;
	transition:all 0.3s;
	z-index:4;
}
.appoint-bx1 .focused .input-bx:after{
	right: auto;
	left:0;
	width:100%;
}
.appoint-video{
    border-radius: 8px 8px 60px 8px;
    overflow: hidden;
    margin-right: 30px;
	height:100%;
}
.appoint-video img{
	height:100%;
	object-fit:cover;
	width: 100%;	
}
/* contact-bx-area1 */
.contact-bx-area1{
	position: relative;
}
.contact-bx-area1:after {
    content: "";
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #eaf1f9;
    z-index: 0;
}
.contact-bx-area1 .contact-bx{
	z-index: 999;
	position: relative;
	padding: 0 30px;
}

/* contact-bx-area2 */
.contact-bx-area2{
    box-shadow: 0 10px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: -50px;
    z-index: 1;
    position: relative;
}
.contact-bx-area2 .right-info{
	padding:40px;
}
.contact-icon-box .title{
    position: relative;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    line-height: 28px;
}
.contact-icon-box {
    margin-bottom: 20px;
    border: 2px solid rgba(255,255,255,0.3);
    padding: 20px 20px;
    border-radius: 6px;
    background: #fff;
    position: relative;
	z-index: 1;
	overflow: hidden;	
}
.contact-icon-box .bg-icon{
	color: #000;
    font-size: 200px;
    line-height: 180px;
    bottom: -30px;
    right: -40px;
    opacity: 0.05;
}
.contact-icon-box .icon{
    width: 40px;
    height: 40px;
    background: var(--primary);
    border-radius: 6px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}
.contact-icon-box a,
.contact-icon-box p{
	margin-bottom: 4px;
    color: #666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

/* Agency Slider */
.slider1 .tparrows::before{
	font-size: 20px;
	line-height: 60px;
}
.slider1 .tparrows{
	width: 60px;
	height: 60px;
}
.slider1 .tp-rightarrow.tparrows{
	background:#17161f;
}
.slider1 .tp-leftarrow.tparrows{
	background:#fcf810;
}
.slider1 .tp-leftarrow.tparrows::before{
	color:#000;
}

.slider1 .tp-leftarrow.tparrows:hover{
	background:#fff;
}
.slider1 .tp-leftarrow.tparrows:hover:before{
	color:#000;
}
.slider1 .tp-rightarrow.tparrows:hover{
	background:#232eff;
}
.slider1 .tp-rightarrow.tparrows:hover:before{
	color:#fff;
}
.slideinfo{
	position:absolute;
	bottom:0;
	display: flex;
	align-items: center;
	transform: rotate(90deg) translateX(-100%);
	-o-transform: rotate(90deg) translateX(-100%);
	-webkit-transform: rotate(90deg) translateX(-100%);
	-moz-transform: rotate(90deg) translateX(-100%);
	-ms-transform: rotate(90deg) translateX(-100%);
	transform-origin: left bottom;
	-o-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	-webkit-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
}
.slide-social{
	list-style:none;
	margin:0;
	padding:0 30px 0 0;
	
}
.slide-social li{
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
}
.slide-social li:first-child{
	padding-right:50px;
	position:relative;
	margin-right: 30px;
}
.slide-social li:first-child:after{
	height:1px;
	width:40px;
	right:0;
	position:absolute;
	content:"";
	background:#fff;
	top: 10px;
}
.slide-social li a{
	color: #fff;
	padding: 0 5px;
	display:block;
	transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
}
.slider-contact.btn {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    background: #232eff;
    padding: 15px 40px;
	font-size:16px;
}
.contact-sidebar{
    position: fixed;
    right: -400px;
    width: 350px;
    height: 100vh;
    background: #fff;
    z-index: 100001;
    overflow-y: scroll;
	top: 0;
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    
}
.contact-sidebar .inner{
	padding: 40px;
}
.contact-sidebar .title{
	font-size:22px;
	line-height:32px;
	color:#000;
    margin-bottom: 20px;	
}
.contact-sidebar .logo{
	margin-bottom: 20px;
}
.contact-sidebar .logo img{
    height: 40px;
}
.contact-sidebar.open{
	right:0;
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
}
.contact-sidebtn.close{
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
	display:none;
}
.contact-sidebar.open + .contact-sidebtn.close{
    position: fixed;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    left: 0;
    top: 0;
    display:block;
	opacity:1;
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
}
.contact-sidebtn.close i{
    background: var(--primary);
    text-shadow: none;
    color: #fff;
    width: 50px;
    height: 50px;
    display: block;
    opacity: 1;
    line-height: 50px;
    font-size: 18px;
    text-align: center;
}
.contact-infolist {
    margin: 0 0 40px 0;
    padding: 25px 0 5px 0;
    list-style: none;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.contact-infolist li{
	position:relative;
    padding:0px 20px 20px 80px;	
	
}
.contact-infolist li .contact-title{
	color: #020d26;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 2px;
}
.contact-infolist li img{
	position: absolute;
    left: 0;
    top: 10px;
    width: 60px;
}
.contact-infolist li p,
.contact-infolist li a{
    font-size: 16px;
    line-height: 24px;
    color: #555;
    font-weight: 400;
    margin-bottom: 5px;
}

.contact-sidebar .input-group label {
    font-size: 14px;
    font-weight: 400;
    color: #606060;
    position: absolute;
    top: 10px;
    left: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	
}
.contact-sidebar .input-group textarea.form-control {
	height:100px;
}
.contact-sidebar .input-group .form-control {
	border-width: 0 0 2px 0;
    background-color: rgba(0,0,0,0);
    padding: 10px 0;
    box-shadow: none;
    height: 45px;
    font-size: 14px;
    border-color: var(--primary);
    border-radius: 0;
}
.contact-sidebar .focused .input-group label {
    top: -10px;
    font-size: 11px;
    color: var(--primary);
    z-index: 2;
	font-weight: 600;
}
.contact-sidebar .g-recaptcha,
.contact-sidebar #rc-imageselect{
	transform: scale(0.7);
	-moz-transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
}
/* Portfolio Box */
.gallery-top{
	margin-bottom:10px;
}
.protfolio-info {
	margin:0;
	padding:0;
	list-style:none;
	margin-bottom: 50px;
	border: 1px solid rgba(0,0,0,0.1);
}
.protfolio-info.m-b0{
	margin-bottom:0;
}
.protfolio-info li{
	display: flex;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.protfolio-info li:last-child{
	border-bottom:0;
}
.protfolio-info li:nth-child(2n){
	background:#f3f3f3;
}
.protfolio-info li strong,
.protfolio-info li span{
	flex:0 0 50%;
	max-width:50%;
	padding: 10px 15px;
}
.protfolio-info li strong{
	color:#000;
	font-weight: 600;
	font-size: 18px;
}
.protfolio-info li span{
	color:#333	
}
.content-info{
	padding: 30px;
	background:#020d26;
	color:#fff;
}
.gallery-thumbs img{
	opacity:0.5;
}
.gallery-thumbs{
	margin: 8px -4px 0;
}
.gallery-thumbs li{
	border: 0;
    padding: 0 4px;
    width: 25%;
}
.gallery-thumbs .react-tabs__tab--selected img{
	opacity:1;
}
/* Feature Box */
.feature-bx1{
	padding: 50px 30px;
    border-radius: 0;
    background: #fff;
    margin-bottom: 30px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    position: relative;
    z-index: 1;
    text-align: center;
    box-shadow: 0px 20px 30px 0 rgba(0,0,0,0.1);
}
.feature-bx1.active,
.feature-bx1:hover{
	transform:scale(1.05);
}
.feature-container.feature-bx1 .ttr-tilte{
	margin-bottom:10px;
}
.feature-container.feature-bx1 p{
	font-size:18px;
	margin-bottom: 15px;
}
.feature-bx1 .btn-link{
	text-transform: uppercase;
	font-weight: 500;
	border-bottom: 2px solid;
	color: var(--primary);
}
.feature-bx1 .btn-link:hover{
	text-decoration:none;
}
.feature-bx1 .icon-cell i{
	font-size:80px;
}
.feature-bx1 .bg-img{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.feature-bx2 .feature-lg i,
.feature-bx1 .icon-cell{
	line-height: 80px;
}
.feature-bx1:hover,
.feature-bx1.active{
	box-shadow:0 10px 35px -15px var(--primary);
	border-color:#fff;
	background:var(--primary);
}
.feature-bx1.active .icon-cell,
.feature-bx1.active .btn-link,
.feature-bx1.active .ttr-tilte,
.feature-bx1.active p,
.feature-bx1:hover .icon-cell,
.feature-bx1:hover .btn-link,
.feature-bx1:hover .ttr-tilte,
.feature-bx1:hover p{
	color:#fff;
}
.feature-bx1.active .bg-img,
.feature-bx1:hover .bg-img{
	opacity:0.1;
}
/* Feature Box 2 */
.feature-bx2{
	padding:50px 30px 80px 30px;
	border-radius:0;
	background:#020d26;
	margin-bottom:30px;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	text-align:center;
	z-index:1;
}
.feature-bx2 .icon-content{
	overflow:unset;
}
.feature-bx2 .ttr-tilte{
	color:#fff;
}
.feature-bx2 .icon-content p{
	margin-bottom: 0;
	font-size: 18px;
	color:#fff;
	font-weight: 300;
	opacity: 0.8;
}
.feature-bx2 .feature-lg i {
    font-size: 80px;
	line-height:80px;
	color:var(--primary);
}
/* Feature Box 3 */
.feature-bx3{
	padding: 0 30px;
	position:relative;
}
.feature-bx3 .feature-box-xl i {
    font-size: 60px;
}
.feature-bx3 .icon-content p{
	font-size: 18px;
	line-height: 28px;
}
.feature-bx3 .icon-content .ttr-tilte{
	margin-bottom: 5px;
}
.feature-bx3 .icon-no{
	width: 40px;
	height: 40px;
	position: absolute;
	background: var(--primary);
	border-radius: 40px;
	color:#fff !important;
	display: block;
	top: 0;
	right: 0;
	z-index: 5;
	line-height: 40px;
	font-weight:600;
}
.feature-bx3 .feature-box-xl{
	line-height: 136px;
	border:2px dashed #000;
	width: 140px;
	height: 140px;
	position: relative;
}
.feature-bx3.right-img2:after,
.feature-bx3.right-img:after{
	content: "";
    background-image: url(../images/errow.png);
    width: 210px;
    height: 75px;
    position: absolute;
    right: -120px;
    top: 35px;
    opacity: 0.25;
	background-repeat: no-repeat;
background-size: 100%;	
}
.feature-bx3.right-img2:after{
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
}










.bg-icon {
    color:#fff;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -1;
    font-size: 200px;
    opacity: 0.05;
}
.feature-btn{
	width: 60px;
	height: 60px;
	position: absolute;
	background: var(--primary);
	border-radius: 50px;
	color: #fff;
	font-size: 35px;
	line-height: 60px;
	bottom: -30px;
	left: calc(50% - 30px);	
	box-shadow: 0px 10px 40px -8px var(--primary);	
	overflow: hidden;
}
.feature-bx2:hover .feature-btn i{
	-webkit-animation: moveUpIcon 0.5s forwards;
	-moz-animation: moveUpIcon 0.5s forwards;
	animation: moveUpIcon 0.5s forwards;
}

@-webkit-keyframes moveUpIcon {
	49% {
		-webkit-transform: translateX(100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes moveUpIcon {
	49% {
		-moz-transform: translateX(100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateX(-100%);
	}
	51% {
		opacity: 1;
	}
}
.feature-btn:after{
	
}
.feature-bx2:hover{
	box-shadow:0 0 30px 0 rgba(0,10,105,0.1);
	transform:translateY(-10px);
	-moz-transform:translateY(-10px);
	-webkit-transform:translateY(-10px);
	-ms-transform:translateY(-10px);
	-o-transform:translateY(-10px);
}
.feature-bx2:hover .feature-btn:after{
	bottom:0;
	top:auto;
	transform-origin:bottom;
	height:100%;
}
.feature-bx2:hover .feature-btn{
	color:#fff;
}
.about-video{
	padding-top:0;
}
.about-video.about-media-bx:before{
	bottom:auto;
	top:0;
}
.about-video .video-media-bx{
	max-width:900px;
	margin:auto;
	box-shadow: -20px 0 50px 0 rgba(0,10,105,0.2);

}
.about-video2:after{
	background:#001536;
	opacity:0.8;
}
.about-video2 .video-media-bx a{
	width: 130px;
    height: 130px;
	line-height: 130px;
	font-size: 28px;
}
.about-video2 .video-media-bx {
    overflow: unset;
	text-align: center;
	height:600px;
}

/* Case Studies Box */
.case-studies-box{
	position:relative;
	border-radius:8px;
	overflow:hidden;
	transition: all 0.5s;
    transform: scale(1);
}
.case-studies-box .studies-info{
	position:absolute;
	bottom:0;
	left:0;	
	padding:30px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0f0f0f+0,000000+100 */
	background: rgb(15,15,15); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(15,15,15,0.0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(15,15,15,0.0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(15,15,15,0.0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f0f0f', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
.case-studies-box .studies-info .studies-tag{
	color:#fff;
	text-transform:uppercase;
	font-weight:600;
	opacity:0.7;
}
.case-studies-box .studies-info .title{
	color:#fff;
	margin-bottom:0;
}
.case-studies-box .studies-info .title a:hover{
	color:var(--sc-primary)
}
.case-studies-carousel .owl-stage{
	display:flex;
	padding-top:20px;
	padding-bottom:40px;
}
.case-studies-carousel .owl-item{
	display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.case-studies-box img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: all 0.25s;
    -moz-transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
	transform: scale(1.03);
	transition: all 0.5s;
}
.case-studies-box:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 25px 0 rgba(0,0,0,0.2);
}
.case-studies-box:hover > img {
    transform: scale(1);
}
/* Client Logo */
.client-logo{
}
.client-logo img{
	height: 90px;
    width: auto !important;
	margin: auto;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	object-fit: contain;
	opacity:0.7;
}
.client-logo:hover img{
	opacity:1;
}
.client-logo.border-1{
	box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
}



/* Btn aware */
.btn.btn-aware{
	background:transparent;
	position: relative;
	overflow: hidden;
	z-index:1;
	border-color: #91ae1d;
}
.btn.btn-aware:hover{
	background-color:transparent;
	border-color: #91ae1d;
}
.btn.black.btn-aware{
	position: relative;
	overflow: hidden;
	background:transparent;
	z-index:1;
	border-color: #000;
}
.btn.btn-aware span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #91ae1d;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	z-index:-1;
}
.btn.black.btn-aware span{
	background-color: #000;
}
.btn-aware:hover span {
    width: 225%;
    height: 562px;
}





/* Video Box */
.video-media-bx{
	overflow:hidden;
	border-radius:4px;
}
.video-media-bx a{
	width: 110px;
    height: 110px;
    background: var(--sc-primary);
    border-radius: 100px;
    text-align: center;
    line-height: 110px;
    color: #fff;
	font-size:40px;
	display:inline-block;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50% , -50%);	
	-moz-transform:translate(-50% , -50%);	
	-webkit-transform:translate(-50% , -50%);	
	-ms-transform:translate(-50% , -50%);	
	-o-transform:translate(-50% , -50%);	
}
.our-partner .heading-bx {
    margin-bottom: 25px;
}
.our-partner-box{
	background:#fff;
	padding:30px;
	border-radius:10px;
	display: flex;
	flex-wrap: wrap;
	box-shadow:0 10px 40px 0 rgba(237,244,255,0.75);
	margin-top: -85px;
	position:relative;
	z-index:1;
}
.our-partner-box .partner-logo{
	-ms-flex: 0 0 33.33%;
	flex: 0 0 33.33%;
	max-width: 33.33%;
	padding: 35px;
	border-right:1px solid #e5efff;
	border-bottom:1px solid #e5efff;
	display: flex;
	align-items: center;
}
.our-partner-box .partner-logo:nth-child(3n){
	border-right:0;
}
.our-partner-box .partner-logo:nth-child(7),
.our-partner-box .partner-logo:nth-child(8),
.our-partner-box .partner-logo:nth-child(9){
	border-bottom:0;
}
/* Team */
.team-member{
	max-width: 700px;
	margin: auto;
}
.team-members-area{
	margin-bottom:50px;
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
    -ms-justify-content: center;
}
.team-members-area .item{
    padding: 0 10px 10px;
}
.team-members-area .team-media{
	height:90px;
	width:90px;
	border-radius:90px;
	position:relative;
	overflow:hidden;
	margin: auto;
	cursor:pointer;
}
.team-members-area .team-media:after{
	content:"";
	position:absolute;
	left:0;
	top:0;
	background:var(--primary);
	width:100%;
	height:100%;
	opacity:0.1;
	transition:all 0.5s;
}
.team-members-area .team-media:before{
	color: #fff;
	content: "+";
	font-size: 90px;
	position: absolute;
	z-index: 1;
	text-align: center;
	width: 100%;
	font-weight: 100;
	line-height: 90px;
	transform: scale(0);
	transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	-webkit-transform: scale(0);
	-moz-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}
.team-members-area .team-media:hover:before{
	transform: scale(1);
	transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
}
.team-members-area .team-media:hover:after{
	opacity:0.5;
}
.team-section{
	position:fixed;
	bottom:-100%;
	left:0;
	background:#fff;
	z-index:999;
	width: 100%;
	padding: 50px 15px;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.team-section.active{
	box-shadow: 0 0 100px 0 rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 0 100px 0 rgba(0,0,0,0.1);
	bottom:0;
}
.team-box{
	display:flex;
	flex-wrap:wrap;
	max-width:1140px;
	margin:auto;
	align-items: flex-start;	
}
.close-team{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    background: #000;
    top: -40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 28px;
    font-weight: 300;
}
.team-box .team-media{
	flex:0 0 20%;
	max-width:20%;
}
.team-box .team-info{
	flex:0 0 80%;
	max-width:80%;
	padding-left: 30px;
}
.team-box .team-info p{
	font-size: 15px;
	line-height: 24px;
	color: #000;
	font-weight: 300;
	margin-bottom: 15px;
}
.team-box .team-info span{
	display: block;
	margin-bottom: 15px;
	text-transform: uppercase;
	font-size: 15px;
	color: #a3b1c7;
	font-weight: 600;
}
.team-title {
    margin-bottom: 5px;
}
.team-social{
	margin-bottom:0;
}
.team-social li a{
	width: 35px;
	height: 35px;
	display: block;
	border-radius: 30px;
	background: var(--primary);
	line-height: 35px;
	color: #fff;
	text-align: center;
}
.team-social li a:hover{
	background: var(--sc-primary);
}
.team-member .team-media img{
	width:100%;
}
.team-member .team-media{
	overflow:hidden;
	position:relative;
	border-radius: 4px;
}
.team-member .team-info{
	text-align:center;
	padding: 20px 10px;
}
.team-member .team-info .title{
	font-size: 22px;
    margin-bottom: 5px;
    line-height: 32px;
}
.team-member .team-info span{
	color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
}
.team-member .team-media ul{
	position: absolute;
    bottom: -50px;
    width: 100%;
    text-align: center;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
	padding: 0;
}
.team-member .team-media ul li{
	display:inline-block;
	margin: 0 2px;
}
.team-member:hover .team-media ul{
	bottom: 30px;
}
.team-member .team-media ul li a{
	
}
.team-page .team-member{
	padding: 0px 20px 10px;
}
.team-member.style-1 .team-media img{
    border-radius: 50%;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 10px;
    height: 220px;
    object-fit: cover;	
}
.team-member.style-1 .team-info{
    padding: 10px 10px 20px 10px;
}
.team-member.style-1:hover .team-media ul{
    bottom: 0px;
}
.team-page  .team-member.style-2{
	padding:0;
}
/* Price Box */
.pricebox{
	padding: 50px 30px 30px 30px ;
    background: #fff;
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;	
    position: relative;
    z-index: 1;	
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.pricebox:hover{
	transform:translateY(-15px);
	-moz-transform:translateY(-15px);
	-webkit-transform:translateY(-15px);
	-ms-transform:translateY(-15px);
	-o-transform:translateY(-15px);
}
.pricebox .title{
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 30px;
    line-height: 24px;
    padding: 12px 30px;
}
.pricebox .price-list{
    padding: 30px 0;
    margin: 30px -30px 30px -30px;
    list-style: none;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);	
}
.pricebox .price-list li{
	color: #73819f;
    padding: 10px 0;
    font-weight: 500;
}
.pricebox .price{
	font-size: 50px;
    font-weight: 600;
    color: #273277;
    line-height: 50px;
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: center;	
}
.pricebox .price sub{
    font-size: 16px;
    line-height: 16px;
    color: #73819f;
    margin: 0px 5px 0px 5px;
    position: relative;
    top: 0px;
}
.pricebox.blue .title{
	background: #675fff;
    box-shadow: 10px 10px 50px -15px #675fff;
}
.pricebox.blue .flaticon,
.pricebox.blue .btn-link{
	color: #675fff;
	font-weight:500;
}
.pricebox.red .title{
	background: #fc6765;
    box-shadow: 10px 10px 50px -15px #fc6765;
}
.pricebox.red .flaticon,
.pricebox.red .btn-link{
	color: #fc6765;
	font-weight:500;
}
.pricebox.orange .title{
	background: #ffa143;
    box-shadow: 10px 10px 50px -15px #ffa143;
}
.pricebox.orange .flaticon,
.pricebox.orange .btn-link{
	color: #ffa143;
	font-weight:500;
}
.pricebox .flaticon{
    position: absolute;
    font-size: 200px;
    right: 0;
    top: 0;
    line-height: 200px;
    z-index: -1;
    color: #675fff;
    opacity: 0.05;
}


/* About Box */
.about-counter{
	border: 10px solid var(--primary);
	padding: 40px;
	position:relative;
	margin-right: 25px;	
}
.about-counter:after{
	content: "";
	width: 40px;
	height: 40px;
	position: absolute;
	top: 30px;
	right: -27px;
	border: 10px solid var(--primary);
	border-width: 10px 10px 0px 0;
	transform: rotate(45deg);
	background:#fff;
}
.about-counter .title{
	font-size: 45px;
	line-height: 60px;
	font-weight: 600;
	margin-bottom: 0;	
}
.about-counter h5{
	font-size: 16px;
	color: var(--primary);
	line-height: 22px;
	margin-bottom: 5px;
	letter-spacing: 1px;
}
.about-img-box{
	position:relative;
	padding: 0 0px 40px 80px;
}
.about-img-inner {
	width: 180px;
	height: 180px;
	position: absolute;
	z-index: 1;
	background: var(--primary);
	border: 10px solid #fff;
	color:#fff;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
}
.about-img-inner .title{
	color: #fff;
    font-size: 60px;
    line-height: 60px;
    font-weight: 300;
    margin-bottom: 5px;
}
.about-img-inner .title span{
	font-weight: 700;
	line-height: 60px;
}
.about-img-inner div > span{
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}
.about-img-box3{
	position: relative;
}
.about-img-box3 img{
	border-radius:100%;
	box-shadow: 0px 20px 40px 0 rgba(0,0,0,0.2);
}
.about-img-box3 .img1{
	max-width: 480px;
    padding: 30px;
	border-radius:100%;
	background-image:url(../images/pattern/pt1.png);
}
.about-img-box3 .img2{
	width: 200px;
    position: absolute;
    right: 10px;
    bottom: 0;
}
.about-img-box3 .img2 img{
	border:8px solid #fff;
}
.about-testimonial .testimonial-content{
	padding:0;
	margin-bottom: 20px;
}
.about-testimonial .client-info{
	position: relative;
	padding-left: 115px;
	padding-top: 15px;
}
.about-testimonial .testimonial-info .name{
	margin: 0;
	font-size: 24px;
	line-height: 30px;
	color:#000;
	font-weight: 600;
}
.about-testimonial .testimonial-info p{
	font-size: 16px;
	letter-spacing: 1px;
	font-weight: 400;
	padding: 0;
	margin: 0;
	padding-top: 3px;
}
.about-testimonial .testimonial-thumb{
	box-shadow: unset;
	border: 0;
	top: 0;
	width: 90px;
}

.about-testimonial .testimonial-content p{
	font-size: 22px;
	line-height: 34px;
	padding: 0;
	margin-bottom: 0;
	font-style: italic;
}
.about-img-box img{
	border-radius:10px;
	box-shadow: -10px 0 30px 0 rgba(0,10,105,0.1);
}
.about-img-box img:last-child{
	position: absolute;
    bottom: 0;
    width: 45%;
    left: 0;
}
.video-zoom{
	position:relative;
}
.video-zoom:after,
.video-zoom:before{
	-webkit-animation:2s scrollup infinite linear;
	-moz-animation:2s scrollup infinite linear;
	-o-animation:2s scrollup infinite linear;
	-ms-animation:2s scrollup infinite linear;
	animation:2s scrollup infinite linear;
	content:"";
	width: 100%;
    position: absolute;
    height: 100%;
    border: 1px solid var(--sc-primary);
    left: 0;
    border-radius: 100px;
}

.video-zoom:before{
	-webkit-animation:2s scrollup1 infinite linear;
	-moz-animation:2s scrollup1 infinite linear;
	-o-animation:2s scrollup1 infinite linear;
	-ms-animation:2s scrollup1 infinite linear;
	animation:2s scrollup1 infinite linear;
}
@keyframes scrollup{
	0%{
		transform:scale(1);
		opacity:1;
	}
	100%{
		transform:scale(1.7);
		opacity:0
	}
}
@keyframes scrollup1{
	0%{
		transform:scale(1);
		opacity:1;
	}
	100%{
		transform:scale(1.35);
		opacity:0
	}
}
.number-box{
	padding-left:90px;
	z-index:1;
	position:relative;
}
.number-box .number-icon{
    width: 32px;
    height: 32px;
    background: var(--primary);
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-weight: 600;
    border-radius: 30px;
    position: absolute;
    left: 40px;
    top: -2px;
}
.number-box .number-icon:after{
	content:"";
	position:absolute;
	top:16px;
	left:-40px;
	width: 30px;
    height: 1px;
    background: var(--primary);
}
.number-box:after{
	content: attr(data-name);
    position: absolute;
    font-size: 90px;
    font-weight: 700;
    color: #000;
    opacity: 0.1;
    left: 0;
    top: -10px;
    line-height: 90px;
    z-index: -1;
}
.number-box .btn-link {
	transform: translateY(30px);
    opacity: 0;
}
.number-box:hover .btn-link {
	transform: translateY(0);
    opacity: 1;
}
.number-box p {
    line-height: 24px;
    color: #6c6969;
    margin-bottom: 15px;
}

/* Split Box */
.split-box {
  position: relative;
  overflow: hidden;
  opacity:0;
}
.split-box img{
	width:100%;
}
.split-box > div {
  opacity: 0;
  -webkit-transition: 0s .8s;
  -o-transition: 0s .8s;
  transition: 0s .8s;
}
.split-box:before,
.split-box:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.split-box:before {
  background-color: #020d26;
  z-index: 1;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}
.split-box:after {
  background-color: #5096C9;
  z-index: 2;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}
@-webkit-keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

@keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

.split-box.split-active,
.split-box.split-active > div {
  opacity: 1;
}

.split-box.split-active:after {
  -webkit-animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
  animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
}

.split-box.split-active:before {
  -webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}
/* Split Box END */
/* Product Box */

.item-box{
	margin-bottom:30px;
}
.item-box .item-media{
	overflow:hidden;
	position:relative;
	background: #000;	
}
.item-box .item-media ul {
	padding: 0;
    list-style: none;
    margin: 0;
    position: absolute;
    bottom:-100px;
	left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
	transition:all 0.8s;
	-moz-transition:all 0.8s;
	-webkit-transition:all 0.8s;
	-ms-transition:all 0.8s;
	-o-transition:all 0.8s;
	width: 100%;
    text-align: center;
}
.item-box:hover .item-media ul {
	bottom:20px;
}
.item-box .item-media ul li{
	display:inline-block;
	padding: 0 4px;
}
.item-box .item-media ul li a{
	width: 45px;
    height: 45px;
    display: inline-block;
    background: #020d26;
    color: #fff;
    line-height: 45px;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(237,244,255,0.75);
}
.item-box .item-media ul li a:hover{
	background:var(--primary);
	color:#fff;
}
.item-box .item-info .title{
	margin-bottom:5px;
}
.item-box .item-info{
	padding:20px;
	text-align:center;
border-top: 1px solid rgba(0,0,0,0.15);	
}

.product-full-info .price,
.item-widgets-body .price,
.item-box .price{
	color: #bebebe;
	font-weight:600;
	font-size:18px;
}
.product-full-info .price span,
.item-widgets-body .price span,
.item-box .price span{
	color: var(--primary);
}
.item-box{
	background: #fff;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.15);
    overflow: hidden;
    border-radius: 4px;
}
/* Product details */
.product-full-info .ttr-divider{
	background: rgba(0,0,0,0.15);
}
.product-full-info .price{
	font-size: 20px;
    margin-bottom: 15px;
}
.meta-item,
.product-quantity{
	display: flex;
    align-items: center;
	margin-bottom:30px;
}
.meta-item .title,
.product-quantity .title{
    margin-bottom: 0;
    width: 120px;
}
.meta-item{
	margin-bottom:15px;
}
.meta-item a{
	font-weight: 600;
    color: #74758c;
    margin-right: 10px;
}
.quantity{
	max-width:80px;
}
.product-btn .btn i{
	font-size: 18px;
}
.product-dec{
	color:#74758c;
	font-size:18px;
	line-height:30px;
}
.product-btn .btn{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	padding: 12px 15px;
    margin-right: 10px;
}
.product-btn .btn:hover{
	transform:translateY(-5px);
	-moz-transform:translateY(-5px);
	-webkit-transform:translateY(-5px);
	-ms-transform:translateY(-5px);
	-o-transform:translateY(-5px);
}
.product-info{
	margin-bottom:50px;
}
.product-description .nav{
	margin-bottom: 20px;
}
.product-description .nav li{
	font-size: 20px;
    color: #000;
    font-weight: 600;
    padding: 10px 0px;
    display: inline-block;
	margin-right:30px;
	border-bottom:3px solid rgba(0,0,0,0);
	background-color: transparent;
	border: 0;
}
.product-description .tab-pane {
	padding:20px 0;
}
.product-description .nav li.react-tabs__tab--selected{
	color:var(--primary);
	border-bottom:3px solid var(--primary);
}

.product-description .comment-respond{
	background: #fff;
}
.product-description .comment-form{
	display:flex;
	flex-wrap:wrap;
	margin: 0 -15px;
}
.product-description .comment-form label{
	color:#000;
}
.product-description .comment-form-comment textarea,
.product-description .comment-form-email input,
.product-description .comment-form-author input{
	width: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 12px 20px;
    border-radius: 4px;
}
.product-description .comment-form-email,
.product-description .comment-form-author{
	flex:0 0 50%;
	max-width:50%;
}
.product-description .comment-form-comment,
.product-description .form-submit,
.product-description .comment-form-rating{
	flex:0 0 100%;
	max-width:100%;
	margin-bottom:30px;
}
.comment-form-email,
.product-description .comment-form-author,
.product-description .comment-form-comment,
.product-description .form-submit,
.product-description .comment-form-rating{
	margin-bottom:30px;
	padding:0 15px;
}
.product-description .comment-form-comment textarea:hover,
.product-description .comment-form-email input:hover,
.product-description .comment-form-author input:hover,
.product-description .comment-form-comment textarea:focus,
.product-description .comment-form-email input:focus,
.product-description .comment-form-author input:focus{
	outline:none;
	border-color:var(--primary);
}
.product-description .form-submit input{
	padding: 10px 30px;
    border: 0;
    background: var(--primary);
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 4px;
	cursor:pointer;
}
.form-submit input:hover{
    background: var(--sc-primary);
    color: #000;
}
.rating-stars ul{
	margin:0;
	padding:0;
	list-style:none;
}
.rating-stars ul li{
	display:inline-block;
	color: #959595;
}
.star-rating,
.rating-stars ul li.selected,
.rating-stars ul li.hover{
	color:#ffb805;
}
.product-description .comment-respond .comment-reply-title {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}
.product-description .commentlist .comment_container{
	display:flex;
    align-items: flex-start;
}
.product-description .commentlist{
	margin:0;
	padding:0;
	list-style:none;
}
.product-description .commentlist .comment-text {
	width: 100%;
    padding-left: 20px;
}
.product-description .commentlist .avatar {
	width: 80px;
    max-width: 80px;
    border-radius: 4px;
    background: #fff;
    padding: 4px;
    box-shadow: 0 0 20px 0 rgba(0,10,105,0.1);
}

.product-description .commentlist .author{
	font-size: 20px;
    font-weight: 600;
    color: #000;
    display: block;
}
.product-description .commentlist .meta{
	margin-bottom:10px;
}
.product-description .commentlist .meta span{
	text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 13px;
    color: var(--primary);
}
.product-description .tab-pane p,
.product-description .commentlist .description p{
	color: #74758c;
    font-size: 16px;
    line-height: 30px;
}
.owl-carousel .item-box{
	margin-bottom:0;
}
.product-gallery .nav-tabs .ttr-media{
	position:relative;
	z-index:1;
	cursor:pointer;
}
.product-gallery .nav-tabs .ttr-media:before{
	content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--primary);
    top: 0;
    left: 0;
    opacity: 0.6;
    transform: scale(0);
    transition: all 0.5s;
}
.product-gallery .nav-tabs .ttr-media:hover:before,
.product-gallery .nav-tabs .react-tabs__tab--selected .ttr-media:before{
	transform: scale(1);
}
.product-gallery .nav-tabs .ttr-media:after{
	content: "+";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    color: #fff;
	transform:scale(0);
	-moz-transform:scale(0);
	-webkit-transform:scale(0);
	-ms-transform:scale(0);
	-o-transform:scale(0);
    transition: all 0.5s ease 0.3s;
    -moz-transition: all 0.5s ease 0.3s;
    -webkit-transition: all 0.5s ease 0.3s;
    -ms-transition: all 0.5s ease 0.3s;
    -o-transition: all 0.5s ease 0.3s;
	z-index:1;
}
.product-gallery .nav-tabs .ttr-media:hover:after,
.product-gallery .nav-tabs .react-tabs__tab--selected .ttr-media:after{
	transform: scale(1);
}
.product-gallery .nav-tabs{
	margin: 10px -3px 0;
    border: 0;
}
.product-gallery .nav-tabs li .ttr-media{
	margin: 0 3px;
}
.product-gallery .nav-tabs li{
    width: 25%;
    margin: 0;
    border: 0;
    background-color: transparent;
	padding: 0;
}
/* checkout */
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFF;
    background-clip: border-box;
    border: 1px solid #EFF2F7;
    border-radius: 0.375rem;
}
.card {
    position: relative;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.card-header {
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #EFF2F7;
}
.card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-header {
    border-bottom: 1px solid #EFF2F7;
}
.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
}
.checkout-area p{
	font-size:14px;
}
.checkout-area .form-group{
	
}

.checkout-area .form-control{
	padding: 15px 20px;
    height: 45px;
    border-radius: 4px;
    border-color: #E0E6ED;
    border-width: 1px 1px 1px 1px;
    font-size: 15px;
}
.checkout-area .form-group:focus-within .form-control,
.checkout-area .form-group:focus-within .input-group .input-group-text{
	border-color: var(--primary);
}
.checkout-area .input-group .input-group-text{
	background: #fff;
	border-color: #E0E6ED;
}
.checkout-area .input-group .form-control{
	border-width: 1px 0 1px 1px;
}

/* ================================
	Shop Filter Mobile 
================================ */
.filter-mobile{
	display: none;
    align-items: center;
    text-align: center;
    position: sticky;
    top: 80px;
    background: #fff;
    box-shadow: 0px 10px 30px 0 rgba(0,0,0,0.15);
}
.filter-mobile .filter-bx:last-child{
	width:140px;
}
.filter-mobile .filter-bx{
	width: 100%;
}
.filter-btn{

}
.filter-btn:hover{
	background-color:#000;
	color:#fff;
}
.filter-btn i{
	margin-right:5px;
}
.filter-bx .bootstrap-select .dropdown-toggle{
    border: 0px solid rgba(0,0,0,0.1) !important;
    border-radius: 4px;
    padding: 2px 20px;
    color: #404040;
    height: 46px;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
}
.filter-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 18px;
    right: 20px;
}
.filter-btn.close {
	display:none;
	opacity:0;
}


.cart-box .product-dec{
	padding-left:15px;
	width: 100%;
	padding-right: 60px;
}
.cart-box .product-img{
	max-width:50px;
	width:50px;
}
.cart-box{
	display:flex;
	position: relative;
	padding-bottom: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 15px;
}
.cart-box.last,
.cart-box:last-child{
	border:0;
	padding-bottom:0;
}
.cart-box .product-dec .title{
	margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.cart-box .product-price{
	position: absolute;
    top: 0;
    right: 0;
    color: black;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
}
.cart-box .product-qua{
	font-size: 14px;
}
.card-body .shipping,
.card-body .subtotal,
.card-body .total{
	display: flex;
    padding: 10px 0px;
    border-top: 1px solid rgba(0,0,0,0.1);
}
.card-body .shipping small,
.card-body .subtotal small,
.card-body .total small{
	font-size: 16px;
    font-weight: 600;
    color: #000;
    flex: 0 0 50%;
}
.card-body .shipping span,
.card-body .subtotal span,
.card-body .total span{
	font-weight: 500;
    font-size: 15px;
    color: #505050;
}
/* Cart Table */
.cart-table img{
	width: 50px;
}
.cart-table {
	padding: 1px
}
.cart-table .media-body{
	padding-left:15px;
}
.cart-table .media-body .title{
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 500;
	line-height: 22px;
}
.cart-table thead tr th,
.cart-table thead tr:not(.table-divider){
	border: 0;
    background: rgba(0,0,0,0);
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
}
.cart-table td,
.cart-table th{
	border:0;
	vertical-align: middle;
}
.cart-table .actions{
    padding-right: 20px;
}
.cart-table .actions a{
    padding: 0 5px;
}
.cart-table tr:not(.table-divider){
	border: 0px solid rgba(0,0,0,0.05);
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    background: #fff;
    vertical-align: middle;
}
.cart-table .price{
    font-size: 14px;
    color: var(--primary);
    font-weight: 500;
}
.cart-table .form-control{
	border-radius: 4px;
    padding: 10px 15px;
}
.cart-table .form-control:focus,
.cart-table .form-control:hover{
	border-color:var(--primary);
}
.cart-table .status{
	    font-weight: 500;
	font-size: 14px;
}
.cart-table .total{
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.cart-table .media-body span{
    font-size: 13px;
    font-weight: 500;
}
.table-divider{
	height:20px;	
}
.service-img-bx{
	display:flex;
	align-items:center;
	margin-bottom:60px;
}
.service-img-bx:nth-child(2n){
	flex-direction: row-reverse;
	text-align: right;
}
.service-img-bx .service-info .title h3{
	margin-bottom:10px;
}
.service-img-bx .service-info .title{
	border-left: 5px solid var(--primary);
    padding-left: 30px;
    margin-bottom: 30px;
}
.service-img-bx:nth-child(2n) .title{
	border-right: 5px solid var(--primary);
    padding-left: 0;
    margin-bottom: 30px;
    padding-right: 30px;
    border-left: 0;
}
.service-img-bx .service-info .title p{
	margin-bottom:0;
}
.service-img-bx .service-media img{
	width:100%;
}
.service-img-bx .service-media{
	flex:0 0 65%;
	max-width:65%;	
}
.service-img-bx .video-media-bx{
	border-radius: 4px;
}
.service-img-bx .service-info{
	flex:0 0 35%;
	max-width:35%;	
	padding:0 0 0 50px;
}
.service-img-bx:nth-child(2n) .service-info{
	padding:0 50px 0 0;
}


/* my-account */
.my-account{
	margin: auto;
	width: 450px;
}
.my-account .tab-content form .form-control {
    border: 1px solid #eaeaea;
    height: 50px;
    font-size: 16px;
    padding: 15px 20px;
    border-radius: 5px;
}
.my-account .nav.nav-tabs{
	margin-bottom: 30px;
}
.my-account .forgot-pass{
	float: right;
	padding: 10px 5px;
}



/* Portfolio Box */
.portfolio-area .portfolio-media img{
	object-fit:cover;
}
.portfolio-area .portfolio-media img,
.portfolio-area .portfolio-media,
.portfolio-area .portfolio-box{
	height:100%;
}
.portfolio-box{
	overflow:hidden;
	overflow:-moz-hidden-unscrollable;
	position:relative;
	cursor:pointer;
	
}
.portfolio-box.style-1{
	margin-bottom:4px;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	
}
.portfolio-box.style-1 .magnific-anchor{
	position: absolute;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background: var(--primary);
    color: #fff;
    top: 25px;
    text-align: center;
    line-height: 45px;
    font-size: 28px;
}
.portfolio-box.style-1:hover .magnific-anchor{
	background:#fff;
	color:#000;
}
.portfolio-box.style-1 .title a{
	color:#fff;
}
.portfolio-box.style-1 .portfolio-info{
	position: relative;
}
.portfolio-box.style-1 .portfolio-media{
	overflow:hidden;
	vertical-align:middle;
}
.portfolio-box.style-3 .portfolio-media img,
.portfolio-box.style-2 .portfolio-media img,
.portfolio-box.style-1 .portfolio-media img{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	transform:scale(1.1);
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	-o-transform:scale(1.1);
	-moz-transform:scale(1.1);
}
.portfolio-box.style-3:hover .portfolio-media img,
.portfolio-box.style-2:hover .portfolio-media img,
.portfolio-box.style-1:hover .portfolio-media img{
	transform:scale(1);
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
	-moz-transform:scale(1);
}
.portfolio-box.style-3 .portfolio-info,
.portfolio-box.style-1 .portfolio-info{
	background:#020d26;
	padding:20px 20px;
}
.portfolio-box.style-3 .portfolio-info .title,
.portfolio-box.style-1 .portfolio-info .title{
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 5px;
}
.portfolio-box.style-3 .portfolio-info .exe-title,
.portfolio-box.style-1 .portfolio-info .exe-title{
	text-transform: uppercase;
    font-weight: 600;
    color: var(--primary);
    font-size: 14px;
    letter-spacing: 3px;
}
.portfolio-box.style-2:after{
	content: "";
    background: var(--primary);
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    opacity: 0.9;
    z-index: 1;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	transform-origin:top;
}
.portfolio-box.style-2:hover:after{
	transform-origin:bottom;
	height: 100%;
	top:auto;
	bottom:0;
}
.portfolio-box.style-2 .portfolio-info{
	position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
	z-index:2;
}
.portfolio-box.style-2 .portfolio-info .title{
	font-size: 28px;
    line-height: 38px;
    margin-bottom: 40px;
	color:#fff;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	opacity:0;
}
.portfolio-box.style-3 .portfolio-info .title a,
.portfolio-box.style-2 .portfolio-info .title a{
	color:#fff;
}
.portfolio-box.style-2 .portfolio-info .exe-title{
	text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    letter-spacing: 3px;
	opacity:0;	
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.portfolio-box.style-2:hover .portfolio-info .title{
	 margin-bottom: 5px;
	 opacity:1;
}
.portfolio-box.style-2:hover .portfolio-info .exe-title{
	opacity:0.6;
}
.portfolio-box.style-1.m-b0,
.portfolio-box.style-2.m-b0,
.portfolio-box.style-3.m-b0{
	margin-bottom:0;
}
.portfolio-box.style-3,
.portfolio-box.style-2{
	margin-bottom:4px;
}
.portfolio-box.style-2 .magnific-anchor{
    position: absolute;
    width: 100%;
    height: 55px;
    background: #020d26;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 15px 20px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
	transition:all 0.5s ease 0s;
	-moz-transition:all 0.5s ease 0s;
	-webkit-transition:all 0.5s ease 0s ;
	-ms-transition:all 0.5s ease 0s;
	-o-transition:all 0.5s ease 0s;
	transform:rotateX(90deg);
	-moz-transform:rotateX(90deg);
	-webkit-transform:rotateX(90deg);
	-ms-transform:rotateX(90deg);
	-o-transform:rotateX(90deg);
	transform-origin:bottom;
	-moz-transform-origin:bottom;
	-webkit-transform-origin:bottom;
	-ms-transform-origin:bottom;
	-o-transform-origin:bottom;
}
.portfolio-box.style-2:hover .magnific-anchor{
	transform:rotateX(0deg);
	-moz-transform:rotateX(0deg);
	-webkit-transform:rotateX(0deg);
	-ms-transform:rotateX(0deg);
	-o-transform:rotateX(0deg);
	transition:all 0.5s ease 0.5s;
	-moz-transition:all 0.5s ease 0.5s;
	-webkit-transition:all 0.5s ease 0.5s ;
	-ms-transition:all 0.5s ease 0.5s;
	-o-transition:all 0.5s ease 0.5s;
}
.container-max{
	max-width:1500px;
	padding:0 15px;
	margin:auto;
}
.portfolio-box.style-3{
	border-radius:0;
}
.portfolio-box.style-3 .portfolio-info {
    position: absolute;
    bottom: -100px;
    width: 100%;
    opacity: 0;
	transition:all 0.4s;
	-moz-transition:all 0.4s;
	-webkit-transition:all 0.4s;
	-ms-transition:all 0.4s;
	-o-transition:all 0.4s;
}
.portfolio-box.style-3:hover .portfolio-info{
	opacity: 1;
	bottom: 0;
}
.portfolio-box.style-3:hover .portfolio-media img{
	transform:translateY(-50px);
}

/* Portfolio Carousel 1 Style 1 */
.portfolio-carousel-1.style-1{
	
}
.portfolio-carousel-1.style-1 .portfolio-media img{
	height:calc(100vh - 80px);
	object-fit:cover;
	width: 100%;
}
@media only screen and (max-width: 691px){
	.portfolio-carousel-1.style-1 .portfolio-media img{
		height:calc(100vh - 50px);
	}
}


/* skillbar bar style 1  */
.skillbar { 
	position: relative; 
	display: inline-block; 
	background: rgba(0,0,0,0.15); 
	height: 5px; 
	width: 100%; 
	text-align: left; 
}
.skillbar-bar { 
	height: 5px; 
	border-radius: 0; 
	width: 0px; 
	background:var(--primary); 
	display: inline-block; 
	position: absolute; 
	top: 0; 
	margin-bottom:0px;
	overflow: unset !important;
}
.skillbar-bar:after { 
	content:"";
	border-radius:15px;
	background:#fff;
	width:15px;
	height:15px;
	position:absolute;
	right:-5px;
	border:5px solid var(--primary); 
	top:-5px;
}
.skill-bar-percent { 
	position: absolute;
    top: -28px;
    right: 0;
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
}
.skill-bar-text {
	top: -22px;
	position: absolute;
}
.skillbar-box .title{
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0;
	line-height: 22px;
	text-transform: uppercase;
}
.skillbar-bar-main {
    padding: 30px 30px 20px 30px;
    border: 1px solid rgba(0,0,0,0.1);
	background: #f3f3f3;
}
.skillbar-box {
	margin-bottom:25px;
}
.skillbar-bar-main.white .title{
	color:#fff;
}
.skillbar-bar-main.white .skillbar{
	background: rgba(255,255,255,0.15);
}
.skillbar-bar-main.white .skillbar-bar{
	background:var(--sc-primary);
}
.skillbar-bar-main.white .skill-bar-percent{
    color: var(--sc-primary);
}
/* About Us */
.sign-bx{
	display:flex;
	align-items: center;	
	margin-top:40px;
}
.sign-bx .author-bx h5.title{
	line-height: 26px;
	margin-bottom: 4px;
}
.sign-bx .author-bx{
	border-left: 5px solid var(--primary);
	padding-left: 20px;
	margin-left: 30px;
	padding-top: 5px;
	padding-bottom: 8px;
}
/* Mission Vision */
.misson-tabs li{
	flex: 0 0 25%;
    max-width: 25%;	
    margin-top: 5px;
	background: transparent;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    padding: 25px 20px 30px 20px;
	border: 0;
	border-radius: 0;
}
.misson-tabs li.react-tabs__tab--selected{
	background:#f4f4f4;
	color:#000;
}
.mission-tab-bx{
	max-width:800px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
}
.mission-tab-bx p{
    text-align: left;
    color: #585e6d;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
	margin-bottom: 0;
}
.mission-tab-bx p.br-left{
	border-left: 5px solid var(--primary);
	 padding-left: 40px;
}
.mission-tab-bx .sub-title{
    color: #020d26;
    text-align: left;
    font-weight: 500;
    opacity: 0.95;
}
.mission-tab-bx .title{
	color:#000;
	text-align:center;
	margin-bottom:40px;
}

/* Appointment Form */
.form-area-box .input-group label {
	font-size: 15px;
    font-weight: 400;
    color: #606060;
    position: absolute;
    top: 10px;
    left: 20px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.form-area-box .form-group.focused label {
    top: -20px;
    background: #fff;
    margin: 0;
    z-index: 2;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    left: 0;
}
.form-area-box .input-group .form-control {
    border-width: 0 0 1px 0;
    background-color: rgba(0,0,0,0);
    background: transparent;
    border: 1px solid rgba(0,0,0,0.2);
    height: 45px;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 15px;
	line-height: 22px;
}
.form-area-box .input-group textarea.form-control {
	height:150px;
}
.form-area-box .check-btn .btn {
    color: #555;
    background-color: #eee;
}
.appoint-img img{
	height: 850px;
    width: 100%;
    object-fit: contain;
    margin-top: -50px;
}
.form-area-box .check-btn .btn input{
	display: none;
}
.form-area-box .check-btn .btn.active{
	background-color: var(--primary);
	color: #fff;
}
.get-in-touch.form-area-box .form-group.focused label{
	background:transparent;
}
.form-area-box select{
    border: 1px solid rgba(0,0,0,0.2) !important;
    background-color: transparent !important;
    height: 45px;
    font-size: 15px;
    color: #666;
    border-radius: 4px;
    width: 100%;
    display: block;
	padding: 0 20px;
}
.form-area-box  .input-group-btn{
	display: block;
    width: 100%;
}
.form-area-box .bootstrap-select.btn-group .dropdown-menu{
	border: 0;
    box-shadow: 0px 30px 30px 0 rgba(0,0,0,0.1);
}
.form-area-box .bootstrap-select.btn-group .dropdown-menu.inner{
	padding:10px 0;
}
.form-area-box .custom-control-label{
	font-weight:500;
}
.form-area-box  .bootstrap-select.btn-group .dropdown-toggle .caret{
	top: 16px;
    right: 20px;
}
.bootstrap-select.btn-group .dropdown-menu li a{
	font-size: 15px;
    padding: 8px 20px;
}
/* Case Study Box */
.case-study-box{
    background-color: #fff;
    box-shadow: 10px 0 30px 0 rgba(0,0,0,0.1);
}
.case-study-box .case-content{
    padding: 30px;
    position: relative;
}
.case-study-box .case-content .title{
	margin-bottom: 5px;
}
.case-study-box .case-content p{
	font-size: 18px;
    color: #999;
    line-height: 28px;
    margin-bottom: 20px;
}
.case-study-box .case-content .categary {
	background-color: var(--primary);
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
    display: table;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    position: absolute;
    top: -12px;
    left: 30px;
}


/* Coming Soon */
.comign-soon{
	height:100vh;
	width:100%;
	display:table;
	text-align:center;
}
.comign-soon .comign-soon-area{
	display: table-cell;
    vertical-align: middle;
}
.comign-soon .comign-soon-area .comign-logo{
	width: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.comign-soon .comign-soon-area .countdown {
	margin-bottom:40px;
}
.comign-soon .comign-soon-area .date{
	width: 130px;
    border: 3px solid var(--primary);
    border-radius: 6px;
    display: inline-block;
    margin: 0 10px;
}
.comign-soon .comign-soon-area .time{
	font-size: 80px;
    font-weight: 600;
    display: block;
    color: #020d26;
    line-height: 100px;
}
.comign-soon .comign-soon-area .date span:last-child{
	font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    color: #020d26;
    border-top: 3px solid var(--primary);
    width: 100%;
    display: block;
    line-height: 28px;
    padding: 5px 0;
    margin-top: 5px;
}
.comign-soon .comign-soon-area .title{
	font-size: 70px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 80px;
    margin-bottom: 30px;
}
.comign-soon .subscription-form{
	max-width: 500px;
    display: block;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
.comign-soon .subscription-form .form-control{
	height: 55px;
    border: 3px solid var(--primary);
    border-radius: 30px 0 0 30px;
    padding: 10px 20px 10px 30px;
    font-size: 18px;
    font-weight: 500;
}
.comign-soon .subscription-form .btn{
	border-radius: 0 30px 30px 0;
    font-weight: 600;
    text-transform: uppercase;
    height: 55px;
    padding: 10px 30px 10px 25px;
}
.comign-soon .contact-social-bx li a{
	width: 50px;
    height: 50px;
    font-size: 22px;
    line-height: 44px;
    border: 3px solid;
    color: #020d26;
    margin: 0 3px;
}
.element-box{
	margin-bottom:50px;
}
.element-box:last-child{
	margin-bottom:0;
}
/* index 1 */
.process-area {
    margin-top: -67px;
}
.process-area .container{
	position:relative;
}
.slider-process .slick-slide:nth-child(2n+1) .process-box{
	background:var(--sc-primary);
}
.slider-process .slider-item{
	padding-top: 60px;
}
.port-slider-7 .slick-list{
	padding-top:60px;
}
.process-box{
    background: var(--primary);
    color: #fff;
    text-align: center;
    overflow: hidden;
    transition: all 0.6s;
    -moz-transition: all 0.6s;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -o-transition: all 0.6s;
    padding: 0 0 0px 0;
    position: relative;
}
.process-box .title{
	color: #fff;
    font-weight: 500;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    margin: 0 25px;
}
.process-box .title i{
	font-size: 30px;
    margin-right: 10px;
}
.process-box p{
    padding: 20px 20px;
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 15px;
}
.process-box .process-btn{
    display: block;
    background: #020d26;
    text-transform: uppercase;
    padding: 13px 65px 13px 15px;
    color: #fff;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    position: absolute;
    bottom: -50px;
    width: 100%;
}
.process-box .process-btn i{
	width: 50px;
    height: 50px;
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    color: #000;
    line-height: 50px;	
}
.process-box:hover .process-btn{
	    bottom: 0;
}
.process-box:hover{
    margin-top: -50px;
	padding-bottom:50px;
}
.project-btn{
    position: absolute;
    top: 0;
    z-index: 1;
    background: #fff;
    font-size: 20px;
    font-weight: 400;
    width: calc(25% - 8px);
    right: 15px;
    padding: 15px 60px;
    text-align: center;
}
.project-btn .icon{
    width: 60px;
    position: absolute;
    left: 0;
    border-right: 1px solid rgba(0,0,0,0.2);
    top: 0;
    height: 100%;
    line-height: 60px;
    font-size: 24px;
}
.project-btn .right-icon{
	width: 60px;
    position: absolute;
    right: 0;
    font-size: 20px;
    border-left: 1px solid rgba(0,0,0,0.2);
    height: 100%;
    top: 0;
    line-height: 60px;
}
.portfolio-box.style-2 .portfolio-media img{
	width: 100%;
}
.port-info{
	height: 100%;
	width: 100%;
	padding: 50px;
}
.port-info h2{
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 15px;
}
.port-info p {
    font-size: 18px;
}
@media only screen and (max-width: 591px){
	.port-slider-7 .slick-list {
		padding-top: 30px;
	}
}

/* about-img-box3 */
.about-img-box4 .about-img1 {
    margin-right: 80px;
}
.about-img-box4 .about-img2 {
    margin-left: 50px;
    margin-top: -80px;
    border: 10px solid #fff;
}
.about-img-box4 .about-img-inner {
    width: 250px;
    height: auto;
    padding: 30px;
    text-align: left;
    border-radius: 0;
    right: 0;
    left: auto;
    transform: translateY(-50%);
    top: 40%;
    border: 10px solid #fff;
	box-shadow: 20px 20px 50px 0 rgba(0,0,0,0.1);	
}

/* list-info */
.list-circle li {
    display: block;
    position: relative;
    padding: 5px 5px 5px 30px;
    font-size: 16px;
}
.list-circle li:before {
    content: "\f10c";
}

/* portfolio-box */
.portfolio-box .magnific-anchor.right {
    width: auto;
    float: right;
    right: 0;
    padding-left: 30px;
    padding-right: 30px;
}

/* service-bx style1 */
.service-bx.style1{
	display: flex;
	box-shadow: unset;
	align-items: center;
	border: 1px solid rgba(0,0,0,0.1);
	border-radius: 10px;
}
.service-bx.style1 .service-media{
	width: 120px;
	border-radius: 10px;
	overflow: hidden;
}
.service-bx.style1 .service-info {
    padding: 10px 20px;
}
.service-bx.style1 .service-info p {
    margin-bottom: 10px;
}
.service-bx.style1 .service-info .btn-link{
	font-size: 14px;
}
.service-bx.style1 .service-info .title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
}

/* history-box */
.history-box .nav-tabs {
    border-bottom: 2px solid rgba(255,255,255,0.3);
    justify-content: center;
	margin-bottom: 50px;
}
.history-box .nav-tabs li{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 15px 20px;
    display: block;
	text-transform: capitalize;
    margin-bottom: -1px;
	border-bottom: 2px solid transparent;
	border-width: 0 0 2px;
	background-color: transparent;
}
.history-box .nav-tabs li.react-tabs__tab--selected{
	box-shadow: none;
}
.history-box .nav-tabs li.react-tabs__tab--selected:after{
	content: none;
}
.history-box .nav-tabs li.react-tabs__tab--selected,
.history-box .nav-tabs li:hover{
    border-bottom: 2px solid #FDCC03;
}
.history-box .history-info{
	padding: 50px 30px;
}
.history-box .tab-content .tab-pane img{
	width: 100%;
}

/* testimonial-bx style3 */
.testimonial-bx.style3,
.testimonial-bx.style4{
	background-color: transparent;
	padding: 0 0 40px;
}
.testimonial-bx.style3 .testimonial-content{
	padding-bottom: 30px;
}
.testimonial-bx.style3 .testimonial-content p {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
}
.testimonial-bx.style3 .testimonial-thumb {
    bottom: 50px;
}
.testimonial-bx.style3 .testimonial-info .name,
.testimonial-bx.style3 .testimonial-info p,
.testimonial-bx.style4.text-white .testimonial-content p{
	color: #fff;
}
.testimonial-bx.style3 .testimonial-info {
    margin: 20px 0 20px 100px;
}
.testimonial-bx.style3 .testimonial-info:after,
.testimonial-bx.style4 .testimonial-info:after{
	content: "";
}

/* testimonial-bx style4 */
.testimonial-bx.style4 .testimonial-content p {
    font-size: 30px;
    line-height: 50px;
    font-style: italic;
}
.testimonial-area4 {
    max-width: 700px;
    text-align: center;
}
.testimonial-area4.clinet-thumbs .swiper-wrapper {
    max-width: 260px;
}


/* dots-style-2  */
.dots-style-2 .slick-dots {
    display: inline-block;
    margin: 0 5px;
}
.dots-style-2 .slick-dots li button:before{
	content: none;
}
.dots-style-2 .slick-dots li{
	width: auto;
}
.dots-style-2 .slick-dots li button {
    width: 60px;
    height: 2px;
    background-color: #fff;
    display: block;
	padding: 0;
}
.dots-style-2 .slick-dots li.slick-active button {
	background-color: var(--primary);
}

/* portfolio-box.style-1 */
.portfolio-box.style-1 .bg-white .title a{
	color: #000;
}

/* team-member */
.team-member.style-2 .team-media{
	overflow: unset;
}
.team-member.style-2 .team-social {
    bottom: -22px;
    right: 20px;
    width: 45px;
}
.team-member.style-2 .team-social li {
    display: block;
    margin-bottom: -45px;
    transition: all 0.5s;
    z-index: 9;
    position: relative;
}
.team-member.style-2 .team-social li a{
	height: 45px;
	width: 45px;
	line-height: 45px;
}
.team-member.style-2:hover .team-social{
	bottom: -22px;
}
.team-member.style-2 .team-social:hover li {
	margin-bottom: 5px;
	transition: all 0.5s;
}
.team-member.style-2 .team-social li:last-child{
	z-index: 99;
	margin-bottom: 0;
}
.team-member.style-2 .team-info{
	border-bottom: 2px solid rgba(0,0,0,0.1);
}
.team-member.style-2:hover .team-info{
	border-bottom-color: var(--primary);
}

/* recent-news */
.recent-news .event-time {
    background-color: var(--primary);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    text-align: center;
    color: #fff;
}
.recent-news .event-time .event-date {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
}
.recent-news .event-time .event-month {
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    margin-top: 5px;
}


@media only screen and (max-width: 1400px) {
	.port-info{
		padding:30px;
	}
}
@media only screen and (max-width: 1200px) {
	.menu-logo{
	    width: 140px;	
	}
	.account-head{
		width: 350px;
		min-width: 350px;
	}
	.rev-btn{
		padding-left:20px !important;
		padding-right:20px !important;
	}
	.pricingtable-bx {
		font-size: 80px;
	}
	.our-partner:before, 
	.team-area:before{
		font-size: 170px;
		line-height: 175px;
	}
	.heading-bx h2{
		font-size:40px;
		line-height:50px;
	}
	.about-media-bx .heading-bx h5{
		font-size:18px;
		line-height:30px;
	}
	.counter-style-1 .counter{
		font-size: 45px;
		line-height: 55px;
	}
	.counter-style-1 span {
		font-size: 45px;
		line-height: 55px;
	}
	.counter-style-1 .counter-text{
		font-size: 14px;
		line-height: 28px;
	}
	.about-media-bx .heading-bx{
		margin-top:0;
	}
	.setResizeMargin{
		padding-left:30px;
	}
	.testimonial-carousel .owl-nav{
		left: -20px;
	}
	.our-partner .section-sp3{
		padding-top:50px;
	}
	.blog-md .ttr-post-media {
		width: 200px;
	}
	.menu-links .nav > li .add-menu{
		left: -100px;
	}
	.about-img-box {
		position: relative;
		padding: 0 0px 80px 20px;
	}
	.header-2 .menu-links{
		padding:20px 0 0 20px;
	}
	.header-2 .sticky-header.active .menu-links{
		padding:9px 0 7px 20px;
	}
	.about-counter .title {
		font-size: 38px;
		line-height: 48px;
	}
	.feature-bx1 {
		padding: 25px;
	}
	.feature-bx3.right-img2:after, .feature-bx3.right-img:after{
	    width: 140px;
		height: 65px;
	    right: -90px;	
	}
	.feature-bx3 {
		padding: 0 0px;
	}
	.pricingtable-features li {
		padding: 5px 15px;
	}
	.feature-bx2 {
		padding: 50px 15px 80px 15px;
	}
	.contact-bx-area2 .right-info {
		padding: 40px 20px;
	}
	.service-thumb .title{
		font-size: 16px;
	}
	.service-thumb .title i {
		font-size: 24px;
		line-height: 24px;
		margin-right: 5px;
	}
	.service-box .service-info {
		padding: 30px 15px;
	}
	.testimonial-bx.style1{
		max-width: 400px;
	}
	.side-post .blog-md .ttr-post-media {
		width: 180px;
	}
	.project-btn{
		width: 310px;
	}
	.rev-slider.slide1 .btn{
		margin: 0 -10px!important;
	}
	.style-2 .menu-bar .menu-links .nav {
		padding-right: 10px;
	}
	.rev-slider.slide1 .hades.tparrows {
		width: 50px;
		height: 50px;
	}
	.rev-slider.slide1 .hades.tparrows:before {
		font-size: 20px;
		line-height: 50px;
	}
	.rev-slider.slide1 .hades .tp-arr-allwrapper {
    	width: 50px;
		height: 50px;
	}
	.blog-group .blog-md .ttr-post-media {
		width: 300px;
		max-width: 300px;
		min-width: 300px;
	}
	.history-box .nav-tabs li{
		font-size: 18px;
		padding: 15px 18px;
	}
	.history-box .history-info {
		padding: 0;
	}
}
@media only screen and (max-width: 991px){
	.port-info h2 {
		font-size: 26px;
		line-height: 36px;
	}
	.filter-on .filter-btn.close {
		border: 0;
		z-index: 1;
		position: fixed;
		right: 10px;
		top: 10px;
		color: #fff;
		opacity: 1;
		font-size: 18px;
		display:block;
		transition:all 0.5s;
		-moz-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-ms-transition:all 0.5s;
		-o-transition:all 0.5s;
		opacity:1;
	}	
	.filter-btn.close:hover{
		background:rgba(255,255,255,0.1);
	}
	.filter-mobile{
		display:flex;
		margin-bottom:30px;
	}
	.filter-left{
		padding: 20px 20px;
		width: 280px;
		position: fixed !important;
		left: -310px;
		top: 0;
		background: #fff;
		z-index: 9999999;
		box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
		overflow-y: scroll;
		height: 100%;
		transition:all 0.5s;
		-moz-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-ms-transition:all 0.5s;
		-o-transition:all 0.5s;
	}
	.filter-left.filter-on{
		left: 0;
	}
	.filter-bar{
		top: 100px;
		position: sticky;
		z-index: 999;
	}
	.filter-bar.sticky-top .side-bar{
		z-index:9;
		top:0;
		position:unset;
	}
	.sticky-top.filter-bar{
		z-index:99999999;
		top: 80px;
		position: sticky;
	}
	.filter-left:after{
		content: "";
		position: fixed;
		width: 0;
		background: #000;
		height: 100%;
		z-index: -1;
		left: 0;
		top: 0;
		opacity: 0;
		transition:all 0.5s;
		-moz-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-ms-transition:all 0.5s;
		-o-transition:all 0.5s;
	}
	.filter-left.filter-on:after{
		width: 1000px;
		left: 280px;
		opacity: 0.8;
	}
	.service-info-bx {
		margin-top: 0px;
		padding-top: 80px;
	}
	.account-head {
		width: 100%;
		min-width: 100%;
		height: 200px;
	}
	.account-form {
		display: block;
	}
	.account-container{
		padding: 50px 20px;
	}
	.our-partner:before, 
	.team-area:before {
		font-size: 100px;
		line-height: 100px;
		bottom: -15px;
	}
	.our-partner-box{
		margin-top: 40px;
	}
	.blog-md.md-large .post-title {
		font-size: 28px;
		line-height: 40px;
	}
	.blog-md.md-large .ttr-post-info{
		padding: 10px 0 10px 30px;
	}
	.blog-md.md-large p{
		font-size: 15px;
		line-height: 28px;
	}
	.blog-md.sm-large .media-post li a, 
	.blog-md.md-large .media-post li a{
		font-size: 14px;
	}
	.news-area:before{
		font-size: 90px;
		line-height: 90px;
		top: 30px;
	}
	.about-media-bx:before{
		content:none;
	}
	.video-media-bx img{ 
		width:100%;
	}
	.counter-style-1{
		margin-bottom:40px;
	}
	.news-box .heading-bx.m-t20{
		margin-top:0;
	}
	.about-imgbox{
		margin-top:50px;
	}
	.secondary-inner ul li.extmenu{
		display:none;
	}
	.subscribe-form{
		margin-top:0;
	}
	.page-banner h1 {
		font-size: 40px;
		line-height: 60px;
	}
	.blog-md .ttr-post-media {
		width: 300px;
	}
	.pagination-bx.m-b0,
	.card.m-b0{
		margin-bottom: 0;
	}
	.error-404 {
		display: flex;
	}
	h3 {
		font-size: 30px;
		line-height: 40px;
	}
	.service-img-bx {
		display: block;
	}
	.service-img-bx .service-media {
		max-width: 100%;
	}
	.service-img-bx .service-info {
		max-width: 100%;
		padding: 20px 0 0;
	}
	.service-img-bx:nth-child(2n) .service-info {
		padding: 20px 0 0;
	}
	.about-media-bx.about-video	.video-media-bx.split-box{
		margin-top: 100px;
	}
	.about-media-bx.about-video .row.section-sp1{
		padding-top: 50px;
	}
	.about-img-box {
		position: relative;
		padding: 0 0px 80px 90px;
	}
	.about-video2 .video-media-bx{
		height: 300px;
		margin-bottom: 0;
	}
	.our-story:after{
		content:none;
	}
	.header-2.header-transparent .navbar-toggler span {
		background-color: #232eff;
	}
	.header-2 .navbar-toggler{
		margin: 44px 0 30px 25px;
	}
	.header-2 .sticky-header.active .navbar-toggler {
		margin: 32px 0 32px 25px;
	}
	.header-2 .menu-links,
	.header-2 .sticky-header.active .menu-links{
		padding: 15px;
	}
	.feature-bx3.right-img2:after, .feature-bx3.right-img:after{
		content:none;
	}
	.award-bx.left .award-media {
		min-width: 100%;
	}
	.award-bx.left{
		flex-direction: column;
	}
	.award-bx.left .award-info {
		text-align: center;
		margin-left: 0;
		padding-top: 20px;
	}
	.award-bx {
		padding: 15px 15px 20px 15px;
	}
	.award-bx .award-info .title {
		font-size: 18px;
		line-height:27px;
		margin-bottom:5px
	}
	.award-bx .award-info p {
		font-size: 15px;
		line-height:24px;
	}
	.service-icon-bx:after{
		content:none;
	}
	.misson-tabs li{
		padding: 25px 15px 30px 15px;
	}
	.protfolio-info.m-md-b30{
		margin-bottom:30px;
	}
	.number-box {
		padding-left: 60px;
	}
	.number-box .number-icon{
		left: 20px;
	}
	.number-box .number-icon:after{
		left: -20px;
		width: 15px;
	}
	.number-box:hover .btn-link,
	.number-box p{
		margin-left: -60px;
	}
	.heading-bx h2 {
		font-size: 32px;
		line-height: 45px;
	}
	.apoint-btn-box{
		display:none;
	}
	.client-appoint-box .ajax-form {
		background: #020d26;
		padding: 60px 38px 0px 38px;
		margin: 50px -38px 0;
	}
	.client-appoint-box:after{
		height: 40%;
		bottom: 0;
		top: auto;
		width: 100%;
	}
	.testimonial-bx.style1{
		max-width: 100%;
	}
	.side-post .blog-md .ttr-post-media {
		width: 100px;
	}
	.side-post .blog-md .ttr-post-media img{
		height: 100px;
		object-fit:cover;
	}
	.side-post .blog-md .ttr-post-info h4 {
		font-size: 16px;
		line-height: 24px;
	}
	.side-post .blog-post.blog-md {
		margin-bottom: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
	}
	.service-img-bx:last-child{
		margin-bottom:30px;
	}
	.appoint-video{
		margin-right:0;
	}
	.heading-bx h2 br,
	.page-banner h1 br{
		display:none;
	}
	.aside-bx {
		border-left: 0;
		padding-left: 0;
	}
	.blog-group .recent-news{
		margin-bottom: 30px;
	}
	.history-box .history-info {
		padding: 30px 0 0;
	}
}


@media only screen and (max-width: 767px) {
	.about-img-box {
		position: relative;
		padding: 0px 0px 40px 40px;
	}
	.section-sp1 {
		padding-top: 50px;
		padding-bottom: 20px;
	}
	.section-sp2 {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.section-sp3 {
		padding-top: 50px;
	}
	.news-box .heading-bx br{
		display:none;
	}
	.heading-bx h2 {
		font-size: 28px;
		line-height: 40px;
	}
	.testimonial-carousel .owl-nav{
		position:unset;
		margin-top:30px;
		text-align: left;
	}
	.blog-md.sm-large, 
	.blog-md.md-large{
		flex-direction: column;
	}
	.blog-md.md-large .ttr-post-info{
		padding-left:0;
	}
	.blog-md.sm-large .ttr-post-media,
	.blog-md.md-large .ttr-post-media {
		width: 100%;
		flex: 0 0 100%;
	}
	.post-extra{
		padding-top: 10px;
	}
	.news-area:before{
		top: 0px;
	}
	.page-banner {
		height: 300px;
	}
	.page-banner h1 {
		font-size: 36px;
		line-height: 50px;
	}
	.breadcrumb-row ul li{
		font-size: 14px;
	}
	.breadcrumb-row {
		padding: 5px 15px;
	}
	.recent-news.style-2 {
		margin-bottom: 30px;
	}
	.blog-md .ttr-post-media {
		width: 100%;
	}
	.blog-md.blog-post .ttr-post-info {
		margin-bottom: 0;
	}
	h4 {
		font-size: 24px;
		line-height: 34px;
	}
	blockquote p {
		font-size: 18px;
		line-height: 28px;
	}
	blockquote {
		padding: 10px 20px 10px 25px;
		margin: 20px 0;
	}
	.ttr-post-meta.d-flex{
		display: block!important;
	}
	.ttr-post-meta.d-flex .post-tags{
		margin-bottom: 10px;
	}
	.feature-filters {
		margin-bottom: 30px;
	}
	.table.table-cards{
		width: 700px;
		max-width: 700px;
    }
	.checkout-area .text-right{
		text-align: left!important;
		margin-top: 10px;
	}
	.checkout-area .card-header .text-right{
		text-align: right!important;
		margin-top: 0;
	}
	.timeline-view .left:before {
		transform: rotate(180deg);
	}
	.timeline-view .right:before {
		left: 60px;
	}
	.service-icon-bx .service-icon a {
		font-size: 70px;
		height: 200px;
		width: 200px;
		line-height: 200px;
	}
	h5 {
		font-size: 20px;
		line-height: 30px;
	}
	.case-studies-box .studies-info {
		padding: 20px;
	}
	.case-studies:before, 
	.our-partner:before, 
	.team-area:before {
		font-size: 100px;
		line-height: 120px;
	}
	.case-studies:before {
		top: 0;
	}
	.about-media-bx.about-video	.video-media-bx.split-box {
		margin-top: 0px;
	}
	.about-media-bx.about-video .row.section-sp1 {
		padding-top: 30px;
	}
	.about-video2 .video-media-bx {
		height: 400px;
	}
	.dots-style-1 .owl-dots {
		margin-top: 30px;
	}
	.timeline-box {
		margin-bottom: 40px;
	}
	.service-icon-bx:first-child {
		padding-top: 10px;
	}
	.service-icon-bx{
		padding: 50px 0;
	}
	.service-img-bx{
		margin-bottom:40px;
	}
	.timeline-nav ul {
		padding: 0;
		text-align: center;
	}
	.timeline-nav ul li {
		display: inline-block;
		width: auto;
	}
	.timeline-nav-box {
		position: sticky;
		top: 50px;
		background-color: #fff;
		z-index: 999;
		padding: 10px 30px;
		margin: 0 15px 30px;
	}
	.timeline-nav ul li a.active {
		font-size: 24px;
		padding: 5px 0;
	}
	.header-2.header-transparent .sticky-header.active .container-fluid > .menu-logo,
	.header-2 .container-fluid > .menu-logo{
		padding: 0 10px;
		width: 70px;
		margin-left: -15px;
		height: 70px;
	}
	.header-2 .menu-logo img {
		max-width: 50px;
	}
	.header-2 .sticky-header.active .secondary-menu,
	.header-2 .secondary-menu {
		padding: 25px 0 20px 0;
	}	
	.header-2 .navbar-toggler,
	.header-2 .sticky-header.active .navbar-toggler {
		margin: 28px 0px 28px 15px;
	}
	.header-2 .header-contact .info-no{
		font-size: 16px;
	}
	.counter-style-2 .counter-bx span{
		font-size: 80px;
		line-height: 80px;
	}
	.counter-style-2 .bg-icon{
	    font-size: 110px;
		top: -20px;	
	}
	.mvimg{
		width:100%;
		max-width:100%;
	}
	.about-counter{
		border: 5px solid var(--primary);
		padding: 15px;
		margin-right: 0;
		margin-bottom: 30px;
	}
	.about-counter:after{
		border: 5px solid var(--primary);
		border-width: 5px 5px 0px 0;
		transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		top: auto;
		right: auto;
		bottom: -18px;
		left:20px;
		width: 30px;
		height: 30px;
	}
	.about-counter .title {
		font-size: 28px;
		line-height: 38px;
	}
	.about-img-inner {
		width: 120px;
		height: 120px;
		border: 8px solid #fff;
	    border-radius: 0;	
	    display: block;	
		padding: 5px 0;
	}
	.about-img-inner .title{
	    font-size: 40px;
		line-height: 50px;
		margin-bottom: 0;	
	}
	.about-img-inner .title span{
		line-height: 50px;		
	}
	.about-img-inner div > span{
		font-size: 12px;
		line-height: 18px;
		display: block;
	}
	.sign-img{
		    width: 120px;	
	}
	.sign-bx .author-bx{
	    margin-left: 15px;
	    padding-left: 15px;	
	}
	.feature-filters.style1 li a{
		padding: 2px 10px;
		font-size: 15px;
	}	
	.job-career-box {
		padding: 15px;
	}
	.left-border-1 {
		padding-left: 0;
		border-left: 0;
	}
	.appoint-video{
		margin-right:0;
	}
	.misson-tabs li {
		flex: 0 0 50%;
		max-width: 50%;
		padding-top: 5px;
	}
	.misson-tabs li{
		padding: 12px 15px 12px 15px;
		font-size: 14px;
	}
	.mission-tab-bx p.br-left{
		padding-left: 20px;
	}
	.mission-tab-bx .title{
		margin-bottom: 20px;
	}
	.service-img-bx .service-info .title {
		padding-left: 15px;
		margin-bottom: 20px;
	}
	.service-img-bx:nth-child(2n) .title{
		margin-bottom: 20px;
		padding-right: 15px;
	}
	.ttr-accordion .card-body {
		font-size: 15px;
		line-height: 24px;
	}
	.appoint-img img {
		height: 400px;
		width: 100%;
		object-fit: contain;
		margin-top: 0;
	}
	.comign-soon .comign-soon-area .comign-logo {
		margin-bottom: 10px;
		width: 180px;
	}
	.comign-soon .comign-soon-area .title{
		font-weight: 600;
		font-size: 45px;
		line-height: 50px;
		margin-bottom: 20px;
	}
	.comign-soon .comign-soon-area .date {
		width: 75px;
		border: 2px solid var(--primary);
		margin: 0 2px;
	}
	.comign-soon .comign-soon-area .time{
		font-size: 55px;
		line-height: 65px;	
	}
	.comign-soon .comign-soon-area .date span:last-child{
		font-size: 13px;
		font-weight: 600;
		line-height: 28px;
		padding: 0px 0;
		border-top: 2px solid var(--primary);	
	}
	.comign-soon .subscription-form {
		max-width: 330px;
		margin-bottom: 20px;	
	}
	.comign-soon .comign-soon-area .countdown {
		margin-bottom: 20px;
	}
	.comign-soon .subscription-form .form-control{
		height: 45px;
		border: 2px solid var(--primary);
		padding: 10px 10px 10px 20px;
		font-size: 13px;
		font-weight: 400;
	}
	.comign-soon .subscription-form .btn{
		height: 45px;
		padding: 10px 20px 10px 15px;
	}
	.comign-soon .contact-social-bx li a {
		width: 45px;
		height: 45px;
		font-size: 18px;
		line-height: 41px;
		border: 2px solid;
	}
	.about-img-box3{
		margin-bottom:40px;
	}
	.number-box .btn-link {
		transform: translateY(0);
		opacity: 1;
		margin-left:-60px;
	}
	.number-box{
		margin-bottom:30px;
	}
	.client-appoint-box .ajax-form{
		margin: 50px -45px 0;
	}
	.blog-area .blog-md.blog-post .ttr-post-info {
		margin-bottom: 0;
		width: auto;
		display: table-cell;
		padding: 0 0 0 20px;
	}
	.side-post .blog-post.blog-md{
		padding-left:0;
	}
	.side-post {
		border-left:0;
		margin-bottom:40px;
	}
	.blog-area .float-right{
		 float:none !important;
		margin-top:20px;		
	}
	.about-testimonial .testimonial-content p {
		font-size: 18px;
		line-height: 28px;
	}
	.footer-top{
	    padding: 60px 0 20px;	
	}
	.skillbar-box.m-sm-b0{
		margin-bottom: 0;
	}
	.feature-filters.style1 li a span:after, .feature-filters.style1.white li a span:after{
		height:2px;
	}
	.blog-group .blog-md{
		display: block;
	}
	.blog-group .blog-md .ttr-post-media {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
	}
	.blog-md.blog-post .ttr-post-info{
		padding: 30px;	
		margin: 0;
	}
	
	
}




@media only screen and (max-width: 576px) {
	.testimonial-bx.style4 .testimonial-content p {
		font-size: 22px;
		line-height: 30px;
	}
	.team-members-area {
		margin-bottom: 30px;
	}
	.portfolio-carousel-1.style-1 .portfolio-media img {
		height: calc(100vh - 50px);
	}
	.our-partner:before, 
	.team-area:before {
		font-size: 50px;
		line-height: 65px;
	}
	.our-partner-box .partner-logo{
		    padding: 15px;
	}
	.our-partner-box{
		padding: 15px;
		
	}
	.about-year h2{
		font-size:60px;
		line-height:60px;
	}
	.about-imgbox {
		padding-left: 40px;
	}
	.counter-style-1 span,
	.counter-style-1 .counter {
		font-size: 32px;
		line-height: 40px;
	}
	.about-media-bx .heading-bx {
		margin-bottom: 10px;
	}
	.setResizeMargin {
		padding-left: 15px;
	}
	.blog-md.blog-post .ttr-post-info{
		margin-bottom:0;
	}
	.slide-social li:first-child{
		display:none;
	}
	.page-banner h1 {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 2px;	
	}
	.banner-sm-title{
		font-size:18px;
	}
	.page-banner {
		height: 200px;
	}
	h4 {
		font-size: 22px;
		line-height: 32px;
	}
	.recent-news.style-2 .info-bx p {
		font-size: 14px;
		line-height: 26px;
	}
	.blog-user .author-profile-pic {
		width: 60px;
		min-width: 60px;
		height: 60px;
	}
	.blog-user .author-profile-content h6 {
		font-size: 20px;
		line-height: 26px;
    }
	.pagination > li > a, .pagination > li > span {
		padding: 8px 12px;
		font-size: 12px;
	}
	h5 {
		font-size: 18px;
		line-height: 26px;
	}
	.error-title {
		font-size: 110px;
		line-height: 110px;
	}
	.error-page h5 {
		font-size: 16px;
		line-height: 24px;
	}
	.error-title span:last-child {
		transform: rotateX(180deg) translateY(5px);
		-moz-transform: rotateX(180deg) translateY(5px);
		-webkit-transform: rotateX(180deg) translateY(5px);
		-ms-transform: rotateX(180deg) translateY(5px);
		-o-transform: rotateX(180deg) translateY(5px);
	}
	.my-account {
		width: 100%;
	}
	h3 {
		font-size: 24px;
		line-height: 33px;
	}
	.service-icon-bx {
		display: block;
		max-width: 100%;
		padding: 50px 0;
		text-align:center;
	}
	.service-icon-bx .service-icon {
		margin-bottom: 30px;
		width: 230px;
		margin-left: auto;
		margin-right: auto;
	}
	.service-icon-bx .service-info {
		padding: 0 0 0 0;
		max-width: 100%;
	}
	.service-icon-bx .service-icon:after {
		right: -20px;
    }
	.service-icon-bx:nth-child(2n) .service-icon:after {
		right: -20px;
		left: auto;
	}
	.service-icon-bx:nth-child(2n) .service-info {
		margin-right: auto;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}
	.case-studies:before, 
	.our-partner:before, 
	.team-area:before {
		font-size: 60px;
		line-height: 80px;
	}
	.case-studies:before {
		top: 30px;
	}
	.head-in {
		padding-left: 0;
	}
	.video-media-bx a {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 26px;
    }
	.about-video2 .video-media-bx {
		height: 250px;
	}
	.about-video2 .video-media-bx a {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 24px;
	}
	.client-logo img{
		height: 70px;
	}
	.heading-bx.style2 .title-head{
		font-size: 30px;
		line-height: 45px;
	}
	.heading-bx.style2 p {
		font-size: 16px;
		line-height: 30px;
	}
	.heading-bx.style2.text-white .title-head br,
	.heading-bx.style2 p br{
		display:none;
	}
	.error-page h3 {
		font-size: 40px;
		line-height: 45px;
		margin-bottom: 5px;
	}
	.about-img-box3 .img1{
		padding: 10px;
	}
	.testimonial-bx{
		padding:20px;
	}
	.testimonial-bx.style1 .testimonial-content p{
		font-size: 16px;
		line-height: 24px;
	}
	.service-gallery-top .service-info .service-content{
		padding: 20px 20px;
		width:100%;
	}
	.service-gallery-top .service-info .service-content p{
		font-size:14px;
		line-height:22px;
	}
	.service-box .service-info p,
	.heading-bx p{
		font-size:16px;
		line-height:24px;
	}
	.client-appoint-box .ajax-form {
		margin: 30px -15px 0;
		padding: 40px 15px 0px 15px;
	}
	.rev-slider.slide1 .btn{
		margin: 0!important;
	}
	.skillbar-bar-main {
		padding: 20px 15px 5px 15px;
	}
	.skillbar-box .title{
	    font-size: 15px;
		line-height: 20px;	
	}
	body,
	.protfolio-info li span,
	.protfolio-info li strong{
		font-size: 15px;
	}
	footer p br{
		display:none;
	}
	footer p {
		line-height: 28px;
	}
	.filter-bx .bootstrap-select .dropdown-toggle{
	    height: 40px;	
	}
	.filter-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
		top: 15px;
	}
	.filter-bar {
		top: 60px;
	}
	.about-img-box4 .about-img-inner {
		width: 180px;
		padding: 20px;
		border: 5px solid;
	}
	.about-img-box4 .about-img2 {
		margin-left: 30px;
		margin-top: -50px;
		border: 5px solid;
	}
	.testimonial-bx.style3 .testimonial-content p {
		font-size: 22px;
		line-height: 35px;
	}
	.testimonial-bx.style3 .testimonial-content {
		padding-bottom: 15px;
	}
	.testimonial-bx.style3{
		padding-bottom: 15px;
	}
	.testimonial-bx.style3 .testimonial-thumb{
		bottom: 30px;
	}
	.history-box .nav-tabs li{
		font-size: 15px;
	}
}

/* team  */
@media (min-width: 1200px){
	.container {
		max-width: 1170px;
	}
}

/* Slider Banner */
.tt-slider{
	position: relative;
	overflow: hidden;
}
.tt-slider .slider-item{
	position: relative;
}
.tt-slider .slider-thumb img{
	height: 100%;
    width: 100%;
    object-fit: cover;
}
.tt-slider .slider-thumb{
	height: 700px;
}
.tt-slider .slider-content{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%,-50%);	
}
.tt-slider .slick-arrow{
	background-color: rgba(0,0,0,0.15);
    width: 100px;
    height: 100px;
    z-index: 9;
}
.tt-slider .slick-arrow:before{
    font-family: "fontawesome";
    line-height: 100px;
    font-size: 42px;
    color: #fff;
    display: block;
	background-color: transparent;
}
.tt-slider .slick-arrow.slick-prev:before{
    content: "\f104";
}
.tt-slider .slick-arrow.slick-prev{
	left: 15px;
}
.tt-slider .slick-arrow.slick-next:before{
    content: "\f105";
}
.tt-slider .slick-arrow.slick-next{
	right: 15px;
}
.tt-slider .slick-arrow:hover{
	background-color: #fff;
}
.tt-slider .slick-arrow:hover:before{
	color: #000;
}
.tt-slider .slider-content.text-center .content-inner{
	margin: 0 100px;
}

/* slider-one */
.slider-one .slider-content .sub-title{
    text-transform: uppercase;
    font-family: Barlow;
    line-height: 14px;
    font-size: 14px;
    letter-spacing: 3px;
    font-weight: 500;
    visibility: inherit;
    margin: 0px;
    padding: 8px 15px;    
	display: inline-block;
	margin-bottom: 25px;
}
.slider-one .slider-content .title{
	font-size: 70px;
	line-height: 70px;
	font-family: 'Barlow', sans-serif;
	font-weight: 300;
	margin-bottom: 40px;
}
.slider-one .slider-content .title strong{
	display: block;
	font-weight: 700;
}

/* slider-two */
.slider-two .slider-content .title{
	font-size: 70px;
	line-height: 80px;
    font-weight: 600;
    margin-bottom: 20px;
}
.slider-two .slider-content .sub-title{
	font-size: 16px;
    letter-spacing: 2px;
    font-weight: 400;
}
.slider-two .slider-content{
	margin-top: 50px;
}
.slider-two .slider-thumb{
	height: 900px;
}

/* slider-three */
.slider-three .slider-content .title{
    font-size: 60px;
    line-height: 70px;
    font-weight: 600;
    margin-bottom: 20px;
}
.slider-three .slider-content p{
	font-size: 22px;
    line-height: 32px;
}

/* slider-four */
.slider-four .slider-content .title{
    line-height: 70px;
    font-weight: 800;
    font-size: 70px;
	margin-bottom: 10px;
}
.slider-four .slider-content .sub-title{
	line-height: 20px;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 13px;
	margin-bottom: 30px;
}
.slider-four .slider-content .icon-cell{
	margin: 0 auto 30px;
}

@media only screen and (max-width: 991px) {
	.slider-one .slider-content .title{
		margin-bottom: 30px;
	}
	.tt-slider .slick-arrow{
		width: 60px;
		height: 60px;
	}
	.tt-slider .slick-arrow:before{
		line-height: 60px;
	}
	.slider-three .slider-content .title {
		margin-bottom: 15px;
	}
	.slider-three .slider-content p {
		font-size: 20px;
	}
	.tt-slider .slider-content .title{
		line-height: 62px;
		font-size: 52px;
	}
}
@media only screen and (max-width: 767px){
	.tt-slider .slider-thumb{
		height: 600px;
	}
	.tt-slider .slider-content .title{
		font-size: 42px;
		line-height: 52px;
	}
	.tt-slider .slider-content.text-center .content-inner {
		margin: 0 30px;
	}
	.slider-two .slider-content {
		margin-top: 20px;
	}
}
@media only screen and (max-width: 591px){
	.slider-one .slider-content .title{
		margin-bottom: 25px;
	}
	.tt-slider .slider-thumb{
		height: 500px;
	}
	.slider-three .slider-content .title{
		margin-bottom: 10px;
	}
	.slider-three .slider-content p{
		font-size: 16px;
		line-height: 26px;
	}
	.tt-slider .slider-content .title{
		font-size: 32px;
		line-height: 42px;
	}
	.tt-slider .slider-content.text-center .content-inner {
		margin: 0 0px;
	}
	.slider-four .slider-content .icon-cell{
		margin: 0 auto 15px;
	}
}

/* slick-slide */
.slick-slider{
	margin-left: -15px;
	margin-right: -15px;
}
.slick-slider .slider-item{
	padding-left: 15px;
	padding-right: 15px;
}
.slider-sp0{
	margin-left: 0;
	margin-right: 0;
}
.slider-sp0 .slider-item{
	padding-left: 0;
	padding-right: 0;
}
.slider-sp2{
	margin-left: -2px;
	margin-right: -2px;
}
.slider-sp2 .slider-item{
	padding-left: 2px;
	padding-right: 2px;
}
.slider-sp7{
	margin-left: -7px;
	margin-right: -7px;
}
.slider-sp7 .slider-item{
	padding-left: 7px;
	padding-right: 7px;
}

/* product-carousel */
.product-carousel .slick-dots{
	margin-top: 0;
}

/* portfolio-carousel-2 */
.portfolio-carousel-2{
	margin: 0 -30%;
	overflow: hidden;
}
@media only screen and (max-width: 591px){
	.portfolio-carousel-2{
		margin: 0;
	}	
}

#SRLLightbox{
	z-index: 99999;
}