.inputStyle{
    width: 3rem;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid  gold;
    
    display: inline-block;

    

    
}