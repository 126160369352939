.dropdown {
  position: relative;
  display: inline-block;
  flex-direction: row;
  background-color:#081F43 ;
  border-radius:5px;

}


.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 10px;
}
li:last-child {
  border-bottom: none;
}
li:hover {
  background-color: #e5e5e5;
  color: white;
}
.button{
  padding:12px;
  font-weight:bold;
  color:white;
  margin-right: 10px;
}
.button:before{
  content:"";
  position:absolute;
  width:0px;
  height:0px;
  border: 10px solid;
  border-color: white transparent transparent transparent;
  top: 40%;
  right:1px;
  justify-content: center;
  align-items: center;
}